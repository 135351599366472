import imageCompression from 'browser-image-compression';
import globalConstant from './constants/globalConstant';

export const compressImage = async (image) => {
  const compressedImage = await imageCompression(image, {
    maxSizeMB: globalConstant.MAX_FILE_SIZE_MB,
    maxWidthOrHeight: 400
  });
  const fileTypeComressedImage = new File([compressedImage], image.name, {
    type: image.type || 'image/png'
  });
  return fileTypeComressedImage;
};

export const imagePathResolver = (path) => {
  if (path) {
    return `${process.env.REACT_APP_BASE_API}${globalConstant.IMAGE_PREFIX}/${path}`;
  }
  return '';
};

export const convertToMB = (size) => {
  return size / (1024 * 1024);
};
