import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(isBetween);
dayjs.extend(relativeTime);

export const formatDate = (date) => {
  const formatedDate = dayjs(date);
  const detectedLocale = navigator?.language || 'en-US';
  return new Intl.DateTimeFormat(detectedLocale).format(formatedDate);
  // return dayjs(date).format('MMM DD, YYYY');
};

export const formatTime = (date) => {
  const formatedDate = dayjs(date);
  const detectedLocale = navigator?.language || 'en-US';
  return new Intl.DateTimeFormat(detectedLocale, { timeStyle: 'short' }).format(formatedDate);
  // return dayjs(date).format('hh:mm A');
};

export const formatDateTime = (date) => {
  if (!date) return;
  const dateLabel = formatDate(date);
  const timeLabel = formatTime(date);
  return `${dateLabel} ${timeLabel}`;
  // return dayjs(date).format('MM.DD.YYYY hh:mm');
};

export const formatForMonthAndYear = (date) => {
  return dayjs(date).format('MMMM YYYY');
};

export const dateToday = dayjs().format('DD MMM YY');
export const currentMonth = dayjs().format('YYYY-MM');

//format data and time without seconds for validity of license
export const formatDateTimeWithoutSeconds = (date) => {
  if (!date || !dayjs(date).isValid()) {
    return ''; // Return an empty string or any other custom value like 'N/A'
  }
  // Parse the date as UTC and format without seconds
  return dayjs.utc(date).format('YYYY-MM-DDTHH:mm');
};

// start and end date validation.
export function endDateisBeforeStartDate(startDate, endDate) {
  const newStartDate = dayjs(startDate);
  const newEndDate = dayjs(endDate);
  return newEndDate.isBefore(newStartDate, 'day');
}

export function isCurrentDateIsBetweenTwoDate(startDate, endDate) {
  const startDateFormatted = dayjs(startDate);
  const endDateFormatted = dayjs(endDate).add(1, 'day');
  const currentDate = dayjs();
  return currentDate.isBetween(startDateFormatted, endDateFormatted);
}

export function getMinuteDurationFromNow(dateTime) {
  return dayjs(dateTime).fromNow(true);
}

export const formatDateForUTC = (date) => {
  const formattedDate = new Date(date);
  const options = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'UTC'
  };
  return formattedDate.toLocaleDateString('en-US', options);
};
