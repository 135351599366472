import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InitiativeActivities from 'components/@skeletons/InitiativeActivities';
import InsightBox from 'components/Accessiblity/InsightBox';
import ActivityInfo from 'components/@common/ActivityInfo';
import { useIntl } from 'react-intl';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Typography } from '@mui/material';
import ActivitiesBox from 'components/@skeletons/ActivitiesSkeleton';
import messages from './messages';
import { useGetInitiativeActivityLogsQuery } from 'store/rtk-query/initiativesApi';
import { formatDate, formatTime } from 'utils/dateResolvers';
import { ACTIVITY_NAME } from 'utils/constants/initiatives';
import globalConstant from 'utils/constants/globalConstant';
import { colors } from 'utils/constants/colors';
import { createUserName, getActivityIconBasedOnTableName } from 'utils/utils';

const ActivitiesTab = ({ initiativeId }) => {
  const [offset, setOffSet] = useState(globalConstant.DEFAULT_PAGE_OFFSET);
  const intl = useIntl();
  const { data: { resultList: initiativeActivityLogs = [], totalCount = 0 } = {}, isLoading: getInitiativeActivityLogsIsLoading } =
    useGetInitiativeActivityLogsQuery(
      { initiativeId, offset, limit: globalConstant.DEFAULT_ACTIVITIES_LIST_LIMIT },
      {
        skip: !initiativeId,
        refetchOnMountOrArgChange: true
      }
    );

  if (getInitiativeActivityLogsIsLoading) {
    return Array.from(new Array(9)).map((_, index) => <InitiativeActivities key={index} item container xs={12} />);
  }

  const updateOffset = () => {
    setOffSet((prevOffset) => prevOffset + 1);
  };

  return (
    <InfiniteScroll
      dataLength={initiativeActivityLogs?.length || 0}
      next={updateOffset}
      hasMore={initiativeActivityLogs?.length < totalCount}
      height={'75vh'}
      loader={<ActivitiesBox />}
      endMessage={
        <Typography textAlign={'center'} variant="h5" color={colors.muted[500]} mt={1}>
          {offset !== 0 && initiativeActivityLogs?.length > 0 && intl.formatMessage(messages.NoMoreDataToLoad)}
        </Typography>
      }
    >
      {initiativeActivityLogs.length > 0 ? (
        initiativeActivityLogs.map((activity) => {
          const { id, message, dateTime, tableName, userInfo, entityName, ...rest } = activity;
          const activityInfoIcon = getActivityIconBasedOnTableName(
            tableName === ACTIVITY_NAME.NEWS ? ACTIVITY_NAME.CONTRIBUTIONS : tableName
          );
          const isUserInfoEmpty = Object.values(userInfo).every((info) => !info);

          return (
            <ActivityInfo
              key={id}
              tableName={
                tableName === ACTIVITY_NAME.Initiative_INVITEE
                  ? ACTIVITY_NAME.NETWORK
                  : tableName === ACTIVITY_NAME.Initiative_MEMBER
                  ? ACTIVITY_NAME.NETWORK
                  : tableName === ACTIVITY_NAME.NEWS
                  ? ACTIVITY_NAME.CONTRIBUTIONS
                  : tableName
              }
              activityDate={formatDate(dateTime)}
              activityTime={formatTime(dateTime)}
              entityName={entityName || 'Anonymous'}
              message={message}
              rightIconToRender={activityInfoIcon}
              userName={isUserInfoEmpty ? rest?.initiative?.title : createUserName(userInfo)}
              {...userInfo}
              {...rest}
            />
          );
        })
      ) : (
        <InsightBox
          title={intl.formatMessage(messages.NoActivitiesFound)}
          description={intl.formatMessage(messages.NoActivitiesFoundDescription)}
        />
      )}
    </InfiniteScroll>
  );
};

export default ActivitiesTab;

ActivitiesTab.propTypes = {
  initiativeId: PropTypes.string
};
