import { useNavigate } from 'react-router-dom';
import useLocalStorage from './useLocalStorage';
import { dispatch } from 'store';
import { initiativesApi } from 'store/rtk-query/initiativesApi';
import { keywordsApi } from 'store/rtk-query/keywordsApi';
import { newsApi } from 'store/rtk-query/newsApi';
import { organizationApi } from 'store/rtk-query/organizationApi';
import { userAPI } from 'store/rtk-query/userApi';
import globalConstant from 'utils/constants/globalConstant';
import paths from 'utils/constants/paths';
import { tourApi } from 'store/rtk-query/tourApi';

const useLogout = () => {
  const navigate = useNavigate();
  const [, setJwtToken] = useLocalStorage('');

  const handleLogout = (event) => {
    event.stopPropagation();
    setJwtToken('');
    localStorage.removeItem(globalConstant.JWT_TOKEN);
    dispatch(userAPI.util.resetApiState());
    dispatch(initiativesApi.util.resetApiState());
    dispatch(keywordsApi.util.resetApiState());
    dispatch(newsApi.util.resetApiState());
    dispatch(organizationApi.util.resetApiState());
    dispatch(tourApi.util.resetApiState());
    navigate(paths.Login.url, { replace: true });
  };

  return handleLogout;
};

export default useLogout;
