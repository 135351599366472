import { Avatar, Box, CircularProgress, Grid, styled, Typography, useTheme } from '@mui/material';
import LinkedInitiative from 'components/LinkedInitiativesSection/LinkedInitiativesSection';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Loader from 'components/Progress/Loader';
import PropTypes from 'prop-types';
import NoMoreDataComponent from 'components/Accessiblity/InsightBox';
import { Stack } from '@mui/system';
import { ItemText } from 'components/NewsInfoSection/NewsListing';
import { StyledGreyContainer, StyledTitle } from 'components/InitiativeInfoSection/InviteOrAddNetwork';
import { IconLink, IconUsersGroup, IconLinkOff, IconChartCircles, IconArmchair } from '@tabler/icons-react';
import { useDispatch } from 'react-redux';
import messages from './messages';
import { StyledButton } from './NewsTab';
import {
  useGetAllLinkedinInitiativeQuery,
  useGetRelatedInitiativesQuery,
  useLinkedAnInitiativeMutation
} from 'store/rtk-query/initiativesApi';
import { createUserName, isAdministrator } from 'utils/utils';
import paths from 'utils/constants/paths';
import globalConstant from 'utils/constants/globalConstant';
import { IconButton } from 'components/@common';
import { colors } from 'utils/constants/colors';
import { setInitiativesKeywordState } from 'store/reducers/searchKeywordsReducer';

const LinkedInitiativeContainer = styled(Grid)(() => ({}));

const LinkedInitiativesTab = ({ initiativeInfo, userId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { initiativeId } = useParams();
  const { palette } = useTheme();
  const intl = useIntl();

  const isAdmin = isAdministrator(initiativeInfo, userId);

  const { data: { resultList: linkedInitiatives = [] } = {}, isLoading: isLinkedInitiativeLoading } = useGetAllLinkedinInitiativeQuery({
    initiativeId
  });

  const { data: { resultList: relatedInitiatives = [] } = {}, isLoading: isSimilarInitiativeLoading } = useGetRelatedInitiativesQuery(
    {
      initiativeId,
      offset: 0,
      limit: globalConstant.DEFAULT_PAGINATION_LIMIT
    },
    { skip: !initiativeId }
  );

  const relatedInitiativeExcludingLinkedAndPrivateInitiatives = relatedInitiatives.filter((relatedInitiative) => {
    return !linkedInitiatives.some((linkedInitiative) => linkedInitiative.uuid === relatedInitiative.uuid);
  });

  const [linkedAnInitiative] = useLinkedAnInitiativeMutation();

  const handleDetailsPageNavigation = (uuid) => {
    navigate(`/initiatives/${uuid}`);
  };

  const handleLinkInitiativeNavigation = () => {
    dispatch(
      setInitiativesKeywordState({
        relatedToMe: true
      })
    );
    navigate(`/initiatives/${initiativeId}/linked-initiative/list`);
  };

  if (isLinkedInitiativeLoading || isSimilarInitiativeLoading) return <Loader />;

  return (
    <Grid container>
      <Grid item xs={12} md={9}>
        <LinkedInitiativeContainer>
          {isAdmin && (
            <Grid item alignItems={'center'} mt={2} xs={12} display={'flex'} justifyContent={'flex-end'}>
              <StyledButton sx={{ mr: 2 }} onClick={handleLinkInitiativeNavigation} variant="contained">
                {intl.formatMessage(messages.LinkedInitiative)}
              </StyledButton>
            </Grid>
          )}
          {linkedInitiatives?.length === 0 ? (
            <NoMoreDataComponent
              redirectLink={`${paths.Initiatives.url}`}
              description={intl.formatMessage(messages.NoLinkedInitiativeFound)}
              title={intl.formatMessage(messages.NoLinkedInitiativeYet)}
              redirectButtonText={intl.formatMessage(messages.BackToInitiaitive)}
              onButtonClick={() =>
                dispatch(
                  setInitiativesKeywordState({
                    relatedToMe: true
                  })
                )
              }
            />
          ) : (
            linkedInitiatives?.map((linkedInitiative, index) => (
              <LinkedInitiative key={index} data={linkedInitiative} index={index} initiativeId={initiativeId} isAdmin={isAdmin} />
            ))
          )}
        </LinkedInitiativeContainer>
      </Grid>
      <Grid item xs={12} md={3}>
        {relatedInitiatives?.length === 0 ? (
          <NoMoreDataComponent
            redirectLink={`${paths.Initiatives.url}`}
            description={intl.formatMessage(messages.NoSimilarInitiativeFound)}
            redirectButtonText={intl.formatMessage(messages.BackToInitiaitive)}
            onButtonClick={() =>
              dispatch(
                setInitiativesKeywordState({
                  relatedToMe: true
                })
              )
            }
          />
        ) : (
          <StyledGreyContainer removeTop>
            <StyledTitle>{intl.formatMessage(messages.RelatedInitiative)}</StyledTitle>
            <Grid p={1.5}>
              {relatedInitiativeExcludingLinkedAndPrivateInitiatives?.map((relatedInitiative, index) => {
                const { uuid, title, matchingScore, userInfo } = relatedInitiative;
                const isLinked = linkedInitiatives?.find((linkedInitiative) => linkedInitiative?.uuid === uuid);
                return (
                  <Stack key={index} sx={{ cursor: 'pointer' }} mt={1}>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                      <Stack direction={'row'} spacing={1} alignItems={'center'}>
                        <Box sx={{ position: 'relative', width: 'fit-content' }}>
                          <CircularProgress
                            variant="determinate"
                            value={Number(matchingScore).toFixed(2)}
                            sx={{
                              position: 'absolute',
                              top: '0%',
                              left: '0%',
                              zIndex: 1,
                              width: '1.9rem !important',
                              height: '1.8rem !important'
                            }}
                            color="success"
                            thickness={4}
                          />

                          <Avatar
                            sx={{ width: '30px', height: '30px', backgroundColor: palette.secondary.A400 }}
                            // eslint-disable-next-line react/no-children-prop
                            children={
                              relatedInitiative?.type === globalConstant.COMMUNITY ? (
                                <IconUsersGroup stroke={1.5} color={palette.primary.main} />
                              ) : relatedInitiative?.type === globalConstant.CAMPAIGN ? (
                                <IconChartCircles stroke={1.5} color={palette.primary.main} />
                              ) : relatedInitiative?.type === globalConstant.EVENT ? (
                                <IconArmchair stroke={1.5} color={palette.primary.main} />
                              ) : null
                            }
                          />
                        </Box>
                        <Stack>
                          <ItemText
                            sx={{
                              fontFamily: 'Poppins',
                              fontSize: '14px',
                              fontWeight: 500,
                              lineHeight: '22px',
                              ':hover': {
                                textDecoration: 'underline'
                              }
                            }}
                            onClick={() => handleDetailsPageNavigation(uuid)}
                          >
                            {title || 'Anonymous'}
                          </ItemText>
                          <ItemText
                            sx={{
                              fontFamily: 'Poppins',
                              fontSize: '10px',
                              fontWeight: 400,
                              lineHeight: '15px',
                              textAlign: 'left',
                              ':hover': {
                                textDecoration: 'underline'
                              }
                            }}
                            onClick={() => navigate('/network/' + uuid + '/persons')}
                          >
                            Owner: {createUserName(userInfo) || 'Anonymous'}
                          </ItemText>
                          <Typography color={colors.coolGray[500]} fontSize={9} noWrap>
                            {`${Number(matchingScore).toFixed(2)}% Match`}
                          </Typography>
                        </Stack>
                      </Stack>
                      {isAdmin && (
                        <IconButton
                          onClick={() => linkedAnInitiative({ initiativeId, linkedInitiativeId: uuid, initiativeInfo: relatedInitiative })}
                        >
                          {isLinked ? <IconLinkOff /> : <IconLink />}
                        </IconButton>
                      )}
                    </Stack>
                  </Stack>
                );
              })}
            </Grid>
          </StyledGreyContainer>
        )}
      </Grid>
    </Grid>
  );
};

LinkedInitiativesTab.propTypes = {
  initiativeInfo: PropTypes.object,
  userId: PropTypes.string
};
export default LinkedInitiativesTab;
