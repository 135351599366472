/* eslint-disable import/no-cycle */
import { createApi } from '@reduxjs/toolkit/query/react';
import toast from 'react-hot-toast';
import { RtkBaseQuery } from '../../utils/request';
import { userAPI } from './userApi';
import { usersListApi } from './usersListApi';
import queryTags from 'utils/constants/queryTags';
import { API_REQUEST_METHOD } from 'utils/constants/globalConstant';
import queryEndPoints from 'utils/queryEndPoints';

export const organizationApi = createApi({
  reducerPath: 'organizationApi',
  baseQuery: RtkBaseQuery,
  tagTypes: [queryTags.AllOrganizationUser, queryTags.organizationInfo],
  endpoints: (builder) => ({
    getOrganizationInfo: builder.query({
      query: (uuid) => queryEndPoints.getOrganizationInfo(uuid),
      providesTags: [queryTags.organizationInfo],
      keepUnusedDataFor: 2 * 60, // 2 minutes cache time
      transformResponse: (response) => response?.data?.data
    }),
    getOrganizationsList: builder.query({
      query: ({ offset, limit, search }) => queryEndPoints.getOrganizationsList({ offset, limit, search }),
      providesTags: [queryTags.getOrganizationsList],
      transformResponse: (response) => response?.data?.data,
      serializeQueryArgs: ({ endpointName }) => endpointName
    }),
    userOrgRegistrationApi: builder.mutation({
      query: ({ body }) => {
        return {
          url: queryEndPoints.userOrgRegistration,
          method: API_REQUEST_METHOD.POST,
          body
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const addOrganizationDetails = data?.data?.data;
          const modifiedDataForOrganization = {
            uuid: addOrganizationDetails?.organization.uuid,
            name: addOrganizationDetails?.organization.name,
            email: addOrganizationDetails?.organization.email,
            profileImage: addOrganizationDetails?.organization.profileImage,
            createdAt: addOrganizationDetails?.organization.createdAt,
            createdBy: addOrganizationDetails?.organization.createdBy,
            licenseTransaction: { ...addOrganizationDetails?.licenseTransaction }
          };
          dispatch(
            organizationApi.util.updateQueryData('getOrganizationsList', undefined, (prev) => {
              const currentAllEnterpriseData = prev?.resultList;
              currentAllEnterpriseData.unshift(modifiedDataForOrganization);
            })
          );
          dispatch(
            usersListApi.util.updateQueryData('usersPaymentInformationList', undefined, (prev) => {
              const currentAllEnterpriseData = prev?.resultList;
              currentAllEnterpriseData.unshift(addOrganizationDetails);
            })
          );
        } catch (error) {
          if (error?.error?.status === 409) {
            toast.error(error?.error?.data?.data?.error || 'User already exists with this mail id.');
          } else {
            toast.error(error?.message || 'Failed to register organization user!');
          }
        }
      }
    }),

    updateOrganization: builder.mutation({
      query: ({ organizationId, body }) => {
        return {
          url: queryEndPoints.updateOrganizationById(organizationId),
          method: API_REQUEST_METHOD.PATCH,
          body
        };
      },
      async onQueryStarted({ organizationId }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const updatedOrganizationDetails = data?.data?.data;
          const fullFilledOrganizationDetailUpdate = dispatch(
            organizationApi.util.upsertQueryData('getOrganizationInfo', organizationId, updatedOrganizationDetails)
          );
          const fullFilledUserInfoRegardingOrganizationDetailUpdate = dispatch(
            userAPI.util.updateQueryData('getUserInfo', undefined, (userData) => {
              const isOrganizationExist = !!userData?.organization;
              if (isOrganizationExist) {
                userData.organization = updatedOrganizationDetails;
              }
            })
          );
          const fullFilledEnterpriseUpdate = dispatch(
            organizationApi.util.updateQueryData('getOrganizationsList', undefined, (enterPriseListData) => {
              const currentAllEnterpriseData = enterPriseListData?.resultList;
              const initiativeIndex = currentAllEnterpriseData.findIndex((item) => item.uuid === data?.data?.data?.uuid);
              if (initiativeIndex !== -1) {
                // If the initiative exists, update it
                currentAllEnterpriseData[initiativeIndex] = { ...currentAllEnterpriseData[initiativeIndex], ...data?.data?.data };
              } else {
                // If the initiative doesn't exist, add it to the beginning of the array
                currentAllEnterpriseData.unshift(data?.data?.data);
              }
            })
          );
          await Promise.all([
            fullFilledOrganizationDetailUpdate,
            fullFilledUserInfoRegardingOrganizationDetailUpdate,
            fullFilledEnterpriseUpdate
          ]);
        } catch (error) {
          toast.error(error?.message || 'Failed to update organization details!');
        }
      }
    }),
    getAllOrganizationUsers: builder.query({
      query: ({ organizationId, offset, limit, search }) => queryEndPoints.getAllOrganizationUsers(organizationId, offset, limit, search),
      transformResponse: (response) => response?.data?.data,
      serializeQueryArgs: ({ endpointName }) => endpointName,
      providesTags: [queryTags.AllOrganizationUser]
    }),
    addOrganizationUser: builder.mutation({
      query: ({ organizationId, body }) => {
        return {
          url: queryEndPoints.AddUserInOrganization(organizationId),
          method: API_REQUEST_METHOD.POST,
          body
        };
      },
      invalidatesTags: [queryTags.AllOrganizationUser]
    }),
    updateOrganizationUser: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: queryEndPoints.updateUserInOrganizationById(id),
          method: API_REQUEST_METHOD.PATCH,
          body
        };
      },
      invalidatesTags: [queryTags.AllOrganizationUser]
    }),
    deleteOrganizationUser: builder.mutation({
      query: ({ userId, takeOwnership }) => {
        return {
          url: queryEndPoints.deleteUserInOrganizationById(userId, takeOwnership),
          method: API_REQUEST_METHOD.DELETE
        };
      },
      invalidatesTags: [queryTags.AllOrganizationUser]
    }),
    uploadOrganizationProfileImage: builder.mutation({
      query: ({ organizationId, imageFile }) => {
        const bodyFormData = new FormData();
        bodyFormData.append('file', imageFile);
        return {
          url: queryEndPoints.uploadOrganizationProfileImage(organizationId),
          method: API_REQUEST_METHOD.POST,
          body: bodyFormData
        };
      },
      async onQueryStarted({ organizationId }, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        const updateOrgDetails = data?.data?.data;
        await dispatch(organizationApi.util.upsertQueryData('getOrganizationInfo', organizationId, updateOrgDetails));
      }
    })
  })
});

export const {
  useGetOrganizationInfoQuery,
  useLazyGetOrganizationInfoQuery,
  useGetAllOrganizationUsersQuery,
  useLazyGetAllOrganizationUsersQuery,
  useAddOrganizationUserMutation,
  useUpdateOrganizationUserMutation,
  useDeleteOrganizationUserMutation,
  useUpdateOrganizationMutation,
  useUploadOrganizationProfileImageMutation,
  useGetOrganizationsListQuery,
  useUserOrgRegistrationApiMutation
} = organizationApi;
