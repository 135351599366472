import { Grid, Skeleton } from '@mui/material';

const ProfilePageSkeleton = () => {
  return (
    <Grid container spacing={2}>
      <Grid p={2} item xs={12}>
        <Skeleton variant="text" sx={{ fontSize: '4rem' }} />
      </Grid>
      <Grid item xs={12} md={2} sm={4} xl={2}>
        <Skeleton variant="circular" width={200} height={200} />
        <Skeleton variant="rounded" height={'100%'} sx={{ mt: 4 }} />
      </Grid>
      <Grid item xs={12} md={10} sm={8} xl={10} sx={{ mt: { xs: 2, md: 0 } }}>
        <Skeleton variant="rounded" width={'100%'} height={'150%'} />
      </Grid>
    </Grid>
  );
};

export default ProfilePageSkeleton;
