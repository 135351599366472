// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  IconHome2,
  IconRadioactive,
  IconAffiliate,
  IconNotebook,
  IconSchool,
  IconExchange,
  IconMail,
  IconFileStack
} from '@tabler/icons-react';
import { IconBrandSpeedtest, IconPackages } from '@tabler/icons-react';
import paths from 'utils/constants/paths';
// project import
import { useSelector } from 'store';

// assets

const icons = {
  dashboardOutlined: IconBrandSpeedtest,
  goldOutlined: IconPackages
};

// ==============================|| MENU ITEMS ||============================== //

export const menuItems = {
  items: [
    {
      id: 'sample',
      type: 'group',
      children: [
        {
          id: paths.MyInnofuse.pathTitle,
          title: <FormattedMessage id={paths.MyInnofuse.pathTitle} defaultMessage={'My Innofuse'} />,
          type: 'item',
          url: paths.Root.url,
          disabled: false,
          icon: IconHome2
        },
        {
          id: paths.Reactor.pathTitle,
          title: <FormattedMessage id={paths.Reactor.pathTitle} defaultMessage={'Reactor'} />,
          type: 'item',
          url: paths.Reactor.url,
          disabled: false,
          icon: IconRadioactive
        },
        {
          id: paths.Network.pathTitle,
          title: <FormattedMessage id={paths.Network.pathTitle} defaultMessage={'Networking Hub'} />,
          type: 'item',
          url: paths.Network.url,
          disabled: false,
          icon: IconAffiliate
        },
        {
          id: paths.Initiatives.pathTitle,
          title: <FormattedMessage id={paths.Initiatives.pathTitle} defaultMessage={'Initiatives Hub'} />,
          type: 'item',
          url: paths.Initiatives.url,
          disabled: false,
          icon: IconNotebook
        },
        {
          id: paths.Trainings.pathTitle,
          title: <FormattedMessage id={paths.Trainings.pathTitle} defaultMessage={'Trainings'} />,
          type: 'item',
          url: paths.Trainings.url,
          disabled: true,
          icon: IconSchool
        },
        {
          id: paths.Services.pathTitle,
          title: <FormattedMessage id={paths.Services.pathTitle} defaultMessage={'Services'} />,
          type: 'item',
          url: paths.Services.url,
          disabled: true,
          icon: IconExchange
        },
        {
          id: paths.ContactUs.pathTitle,
          title: <FormattedMessage id={paths.ContactUs.pathTitle} defaultMessage={'Contact Us'} />,
          type: 'item',
          url: paths.ContactUs.url,
          disabled: false,
          icon: IconMail
        },
        {
          id: paths.TermsAndPolicies.pathTitle,
          title: <FormattedMessage id={paths.TermsAndPolicies.pathTitle} defaultMessage={'Terms & Policies'} />,
          type: 'item',
          // url: paths.ContactUs.url,
          disabled: false,
          icon: IconFileStack
        }
      ]
    }
  ]
};

const DashboardMenu = () => {
  const { menuDashboard } = useSelector((state) => state.menuReducer);

  const SubChildrenLis = (SubChildrenList) => {
    return SubChildrenList?.map((subList) => {
      return {
        ...subList,
        title: <FormattedMessage id={`${subList.title}`} />,
        icon: icons[subList.icon]
      };
    });
  };

  const menuList = (subList) => {
    let list = {
      ...subList,
      title: <FormattedMessage id={subList.title === 'components' ? `${subList.title}` : `${subList.title}`} />,
      icon: icons[subList.icon]
    };

    if (subList.type === 'collapse') {
      list.children = SubChildrenLis(subList.children);
    }
    return list;
  };
  const ChildrenList = menuDashboard?.children?.map((subList) => {
    return menuList(subList);
  });

  const dashboardList = {
    ...menuDashboard,
    title: <FormattedMessage id={`${menuDashboard.title}`} />,
    icon: icons[menuDashboard.icon],
    children: ChildrenList
  };

  return dashboardList;
};

export default DashboardMenu;
