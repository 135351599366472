import globalConstant, { VERSION } from './constants/globalConstant';

const queryEndPoints = {
  login: '/login',
  users: '/users',
  usersPaymentInformationV1: (offset = globalConstant.DEFAULT_PAGE_OFFSET, limit = globalConstant.DEFAULT_PAGINATION_LIMIT, search = '') =>
    `/user/${VERSION.V1}/user-payment-list?offset=${offset}&limit=${limit}&search=${search}`,
  getUserByUUID: (uuid) => `/user/get-user-by/${uuid}`,
  getUserInfoByTokenV1: `/user/${VERSION.V1}/get-user`,
  updateUserById: (uuid) => `/user/update-user/${uuid}`,
  uploadUserProfileImage: (userId) => `/${VERSION.V1}/user/${userId}/upload-image`,
  getOrganizationInfo: (uuid) => `/organization/${VERSION.V1}/get-organization/${uuid}`,
  getAllOrganizationUsers: (
    organizationId,
    offset = globalConstant.DEFAULT_PAGE_OFFSET,
    limit = globalConstant.DEFAULT_PAGINATION_LIMIT,
    search = ''
  ) => `/organization/${VERSION.V1}/${organizationId}/users?offset=${offset}&limit=${limit}&search=${search}`,
  getAllSearchUser: (offset = globalConstant.DEFAULT_PAGE_OFFSET, limit = globalConstant.DEFAULT_INITIATIVES_LIST_LIMIT, search = '') =>
    `/user/${VERSION.V1}/get-all-user/search?offset=${offset}&search=${search}&limit=${limit}`,
  AddUserInOrganization: (uuid) => `/organization/${VERSION.V1}/${uuid}/add-users`,
  updateUserInOrganizationById: (uuid) => `/user/update-user/${uuid}`,
  deleteUserInOrganizationById: (uuid, takeOwnership) => `/user/remove-user/${uuid}?takeOwnership=${takeOwnership}`,
  uploadOrganizationProfileImage: (organizationId) => `/${VERSION.V1}/organization/${organizationId}/upload-image`,
  sendSetPasswordMail: (sendMail) => `${VERSION.V1}/send-set-password-mail?sendMail=${sendMail}`,
  getInitiativesById: (userId, uuid) => `/initiative/${VERSION.V1}/user/${userId}/get-initiative/${uuid}`,
  getAllInitiatives: (
    uuid,
    offset = globalConstant.DEFAULT_PAGE_OFFSET,
    limit = globalConstant.DEFAULT_PAGINATION_LIMIT,
    initiativeState = '',
    initiativeType = '',
    filterKeywords = '',
    networkType = '',
    relatedToMe = '',
    relatedToInitiative = '',
    searchReactor = '',
    sortIn = '',
    sortBy = ''
  ) =>
    `/initiative/${VERSION.V2}/user/${uuid}/all-initiatives?offset=${offset}&limit=${limit}&initiativeState=${initiativeState}&initiativeType=${initiativeType}&filterKeywords=${filterKeywords}&networkType=${networkType}&relatedToMe=${relatedToMe}&relatedToInitiative=${relatedToInitiative}&searchReactor=${searchReactor}&sortIn=${sortIn}&sortBy=${sortBy}`,
  addInitiatives: (uuid) => `/initiative/${VERSION.V1}/user/${uuid}/add-initiative`,
  updateInitiatives: (userId, uuid) => `/initiative/${VERSION.V1}/user/${userId}/update-initiative/${uuid}`,
  deleteInitiatives: (userId, uuid) => `/initiative/${VERSION.V1}/user/${userId}/delete-initiative/${uuid}`,
  addExpertise: `/expertise/${VERSION.V1}/add-expertise`,
  addKeywordsByUserIdV1: (userId) => `/keyword/${VERSION.V1}/user/${userId}/add-keyword`,
  addKeywordsByOrganizationIdV1: (organizationId) => `/keyword/${VERSION.V1}/organization/${organizationId}/add-keyword`,
  deleteKeywordByUserIdAndKeywordId: (userId, keywordId) => `/keyword/${VERSION.V1}/user/${userId}/delete-keyword/${keywordId}`,
  deleteKeywordByOrganizationIdAndKeywordId: (organizationId, keywordId) =>
    `/keyword/${VERSION.V1}/organization/${organizationId}/delete-keyword/${keywordId}`,
  getUserKeywordsByUserId: (userId) => `/keyword/${VERSION.V1}/user/${userId}/keywords`,
  getKeywordsByOrganizationId: (organizationId) => `/keyword/${VERSION.V1}/organization/${organizationId}/keywords`,
  getKeywordRecommended: (userId) => `keyword/${VERSION.V1}/user/${userId}/recommended-keywords`,
  resetPasswordLinkExpiration: (uuid) => `/user/${VERSION.V1}/reset-link/${uuid}/check-expiration`,
  setPasswordLinkExpiration: (uuid) => `/user/${VERSION.V1}/set-password-link/${uuid}/check-expiration`,
  setPassword: (uuid) => `/user/set-password/${uuid}`,
  resetPassword: (uuid) => `/user/reset-password/${uuid}`,
  forgetPasswordEmail: '/send-reset-password-mail',
  changePassword: `/user/change-password`,
  getTransactionInfoByUserId: (userId) => `/user/${VERSION.V1}/get-user-license/${userId}`,
  getAllInitiativesReactions: (initiativeId) => `/reaction/${VERSION.V1}/initiative/${initiativeId}/reactions`,
  addInitiativesReaction: (initiativeId) => `/reaction/${VERSION.V1}/initiative/${initiativeId}/add-reaction`,
  getInitiativeMemberByIntiativeId: (initiativeId) => `/initiative/${VERSION.V1}/${initiativeId}/initiative-members`,
  getInitiativeAdminsByIntiativeId: (initiativeId) => `/initiative/${VERSION.V1}/${initiativeId}/initiative-administrators`,
  getInitiativeOwnerByIntiativeId: (initiativeId) => `/initiative/${VERSION.V1}/${initiativeId}/initiative-owner`,
  deleteInitiativeMemberByIntiativeId: (initiativeId, memberUserId) =>
    `/initiative/${VERSION.V1}/${initiativeId}/delete-initiative-member/${memberUserId}`,
  getSearchedUser: ({ search = '', offset = globalConstant.DEFAULT_PAGE_OFFSET, limit = globalConstant.DEFAULT_INITIATIVES_LIST_LIMIT }) =>
    `/${VERSION.V1}/search?searchTerm=${search}&searchIn=network&offset=${offset}&limit=${limit}`,
  addInvitee: ({ initiativeId }) => `/initiative/${VERSION.V1}/${initiativeId}/add-initiative-invitee`,
  addOrganizationAuthority: ({ initiativeId }) => `/initiative/v1/${initiativeId}/add-initiative-organization-authority`,
  setInitiativeInviteeStatus: (initiativeId, inviteeUserId, status) =>
    `initiative/${VERSION.V1}/${initiativeId}/invitee/${inviteeUserId}?accepted=${status}`,
  getInitiativeInvitee: ({ initiativeId }) => `initiative/${VERSION.V1}/${initiativeId}/initiative-invitees`,
  getOrganizationAuthority: ({ initiativeId }) => `initiative/${VERSION.V1}/${initiativeId}/initiative-organization-authority`,
  deleteInitiativeInvitee: ({ initiativeId, inviteeUserId }) =>
    `initiative/${VERSION.V1}/${initiativeId}/delete-initiative-invitee/${inviteeUserId}`,
  deleteOrganizationAuthority: ({ initiativeId, inviteeUserId }) =>
    `initiative/${VERSION.V1}/${initiativeId}/delete-initiative-organization-authority/${inviteeUserId}`,
  getAllKeywords: ({ offset = globalConstant.DEFAULT_PAGE_OFFSET, limit = globalConstant.DEFAULT_PAGINATION_LIMIT, search }) =>
    `/keyword/v1/all-keywords?offset=${offset}&limit=${limit}&search=${search}`,
  addInitiativeComment: ({ initiativeId }) => `comment/${VERSION.V1}/initiative/${initiativeId}/add-comment`,
  getAllInitiativeComments: (initiativeId, offset, limit) =>
    `comment/${VERSION.V1}/initiative/${initiativeId}/initiative-comments?offset=${offset}&limit=${limit}`,
  deleteInitiativeComments: ({ initiativeId, commentId }) => `comment/${VERSION.V1}/initiative/${initiativeId}/delete-comment/${commentId}`,
  updateInitiativeComments: ({ initiativeId, commentId }) =>
    `comment/${VERSION.V1}/initiative/${initiativeId}/comment/${commentId}/update-comment`,
  joinInitiativesRequest: (initiativeId) => `/initiative/${VERSION.V1}/${initiativeId}/join-initiative`,
  joinInitiativesStatus: (initiativeId, doorKnockId, status) =>
    `initiative/${VERSION.V1}/${initiativeId}/door-knock/${doorKnockId}?accepted=${status}`,
  updateOrganizationById: (uuid) => `organization/${VERSION.V1}/update-organization/${uuid}`,
  getNewsById: (initiativeId, newsId) => `/news/${VERSION.V1}/initiative/${initiativeId}/get-news/${newsId}`,
  getAllNews: (initiativeId) => `/news/${VERSION.V1}/initiative/${initiativeId}/all-news`,
  AddNews: (initiativeId) => `/news/${VERSION.V1}/initiative/${initiativeId}/add-news`,
  updateNews: ({ initiativeId, newsId }) => `/news/v1/initiative/${initiativeId}/update-news/${newsId}`,
  deleteNews: ({ initiativeId, newsId }) => `/news/${VERSION.V1}/initiative/${initiativeId}/delete-news/${newsId}`,
  addNewsReaction: (initiativeId, newsId) => `/reaction/${VERSION.V1}/initiative/${initiativeId}/news/${newsId}/add-reaction`,
  addNewsComment: (initiativeId, newsId) => `/comment/${VERSION.V1}/initiative/${initiativeId}/news/${newsId}/add-comment`,
  getAllNewsComments: (initiativeId, newsId, offset, limit) =>
    `/comment/${VERSION.V1}/initiative/${initiativeId}/news/${newsId}/news-comments?offset=${offset}&limit=${limit}`,
  updateNewsComment: ({ newsId, commentId }) => `/comment/${VERSION.V1}/news/${newsId}/comment/${commentId}/update-comment`,
  deleteNewsComment: ({ newsId, commentId }) => `/comment/${VERSION.V1}/news/${newsId}/delete-comment/${commentId}`,
  getOrganizationInfoForUserRegistration: (organizationId) =>
    `/organization/${VERSION.V1}/user-registration?organizationId=${organizationId}`,
  userRegistrationForOrganization: (organizationId) => `/organization/${VERSION.V1}/user-registration?organizationId=${organizationId}`,
  getNetworkListByUserId: ({
    networkType = '',
    initiativeId = '',
    offset = 0,
    limit,
    filterKeywords = [],
    searchReactor = '',
    relatedToMe = true
  }) =>
    `/network/${VERSION.V2}/user-networks?offset=${offset}&limit=${limit}&networkType=${networkType}&initiativeId=${initiativeId}&filterKeywords=${filterKeywords}&searchReactor=${searchReactor}&relatedToMe=${relatedToMe}`,
  getNetworkDetailsByUserId: (networkUserId, networkType) => `/network/${VERSION.V2}/${networkUserId}?networkType=${networkType}`,
  getAllInitiativesTabsCount: (initiativeId) => `/initiative/${VERSION.V1}/${initiativeId}/tab-items-count`,
  getAllNetworksTabsCount: (networkId, networkType) => `/network/${VERSION.V1}/${networkId}/tab-items-count?networkType=${networkType}`,
  globalSearch: ({ keyword, searchIn }) => `/${VERSION.V2}/search?searchTerm=${keyword}&searchIn=${searchIn}`,
  getChatByUserId: (senderId, receiverId, offset = globalConstant.DEFAULT_PAGE_OFFSET, limit = globalConstant.DEFAULT_PAGINATION_LIMIT) =>
    `/${VERSION.V1}/messages/${senderId}/${receiverId}?offset=${offset}&limit=${limit}`,
  recentChatUserList: `/${VERSION.V1}/sender-user-list`,
  messagesCount: `/${VERSION.V1}/new-message-count`,
  // activity logs
  getInitiativeActivityLogs: ({
    initiativeId,
    offset = globalConstant.DEFAULT_PAGE_OFFSET,
    limit = globalConstant.DEFAULT_PAGINATION_LIMIT
  }) => `entity-audit-log/${VERSION.V1}/activities/initiative/${initiativeId}?offset=${offset}&limit=${limit}`,
  getNetworkActivityLogs: ({
    networkId,
    offset = globalConstant.DEFAULT_PAGE_OFFSET,
    limit = globalConstant.DEFAULT_PAGINATION_LIMIT,
    networkType
  }) => `network/${VERSION.V1}/${networkId}/activities?offset=${offset}&limit=${limit}&networkType=${networkType}`,
  getMessagesById: (id) => `/${VERSION.V1}/messages/${id}`,
  getAllRoles: () => `user/${VERSION.V1}/getAllUserRole`,
  updateUserRole: (userId) => `user/${VERSION.V1}/${userId}/update-role`,
  addProjectExperience: `user/${VERSION.V1}/project/add`,
  updateProjectExperience: (id) => `user/${VERSION.V1}/project/update/${id}`,
  deleteProjectExperience: (id) => `user/${VERSION.V1}/project/delete/${id}`,
  getAllProjectExperienceByUser: `user/${VERSION.V1}/projects`,
  profileVisibility: `user/${VERSION.V1}/profile-visibility`,
  getInitiativeReactionsByIntiativeId: (initiativeId) => `/reaction/${VERSION.V1}/initiative/${initiativeId}/reactions`,
  userMessageToOwner: (initiativeId) => `initiative/${VERSION.V1}/${initiativeId}/contact-owner`,
  addWatchedInitiatives: (initiativeId) => `initiative/${VERSION.V1}/${initiativeId}/add/watchlist`,
  removeWatchedInitiatives: (initiativeId) => `initiative/${VERSION.V1}/${initiativeId}/delete/watchlist`,
  getAllLinkedinInitiative: ({
    initiativeId,
    offset = globalConstant.DEFAULT_PAGE_OFFSET,
    limit = globalConstant.DEFAULT_PAGINATION_LIMIT
  }) => `/initiative/v1/${initiativeId}/get-all-linked-initiative?offset=${offset}&limit=${limit}`,
  getAllRelatedinInitiative: ({
    initiativeId,
    offset = globalConstant.DEFAULT_PAGE_OFFSET,
    limit = globalConstant.DEFAULT_PAGINATION_LIMIT
  }) => `/initiative/v1/${initiativeId}/related-initiatives?offset=${offset}&limit=${limit}`,
  linkedAnInitiative: ({ initiativeId, linkedInitiativeId }) => `/initiative/v1/${initiativeId}/linked-initiative/${linkedInitiativeId}`,
  unLinkedAnInitiative: ({ initiativeId, linkedInitiativeId }) =>
    `/initiative/v1/${initiativeId}/unlinked-initiative/${linkedInitiativeId}`,
  addExtractKeywords: `/${VERSION.V1}/extract-keywords`,
  getAllSimilarInitiatives: (initiativeId) => `/initiative/${VERSION.V1}/${initiativeId}/get-similar`,
  getRecommendedNetwork: (initiativeId) => `/initiative/${VERSION.V1}/${initiativeId}/recommended-network`,
  leaveInitiative: (initiativeId) => `/initiative/${VERSION.V1}/${initiativeId}/leave-initiative`,
  checkInitiativeInWatchedList: (initiativeId) => `/initiative/${VERSION.V1}/${initiativeId}/check-watchlist`,
  checkUserInAuthority: (initiativeId) => `/initiative/${VERSION.V1}/${initiativeId}/check-authority`,
  checkUserOwnerShipInInitiative: (userId) => `/user/${VERSION.V1}/${userId}/check-user-initiative-ownership`,
  getAllTourStates: `/user/${VERSION.V1}/app-tour`,
  addTourStates: (pageName) => `/user/${VERSION.V1}/app-tour?pageName=${pageName}`,
  getOrganizationsList: ({
    offset = globalConstant.DEFAULT_PAGE_OFFSET,
    limit = globalConstant.DEFAULT_INITIATIVES_LIST_LIMIT,
    search = ''
  }) => `/organization/${VERSION.V1}/get-organizations?offset=${offset}&limit=${limit}&search=${search}`,
  getAllCountry: `/bexio/${VERSION.V1}/getAllCountry`,
  userOrgRegistration: `/user/${VERSION.V1}/user-registration`,
  getPackageList: `package/${VERSION.V1}/get-packages`,
  setAdminInviteeStatus: (initiativeId, administratorId, token, status) =>
    `initiative/${initiativeId}/administrator/${administratorId}/${token}?accepted=${status}`,
  getAllUserNotifications: ({ offset = globalConstant.DEFAULT_PAGE_OFFSET, limit = globalConstant.DEFAULT_INITIATIVES_LIST_LIMIT }) =>
    `notification/${VERSION.V1}/user-notifications?offset=${offset}&limit=${limit}`,
  getNewNotificationCount: () => `notification/${VERSION.V1}/new-notification-count`,
  markedAsReadNotification: () => `notification/${VERSION.V1}/mark-as-read`,
  singleNotificationMarkAsRead: ({ notificationId }) =>
    `notification/${VERSION.V1}/single-notification-mark-as-read?notificationId=${notificationId}`,
  getSingleNotifications: ({ notificationId }) => `notification/${VERSION.V1}/get-notification/${notificationId}`,
  updateNotification: ({ notificationId }) => `notification/${VERSION.V1}/update-notification/${notificationId}`,
  addNotification: ({ sendMailToUsers }) => `notification/${VERSION.V1}/add-notification?sendMailToUsers=${sendMailToUsers}`,
  deleteNotification: ({ notificationId }) => `notification/${VERSION.V1}/delete-notification/${notificationId}`,
  exportedUser: () => `export/user-info/export-csv`
};

export default queryEndPoints;
