import { Button, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import NewsListing from 'components/NewsInfoSection/NewsListing';
import NoMoreData from 'components/Accessiblity/InsightBox';
import InitiativeActivities from 'components/@skeletons/InitiativeActivities';
import messages from './messages';
import { useGetUserInfoQuery } from 'store/rtk-query/userApi';
import { useGetAllNewsQuery } from 'store/rtk-query/newsApi';
import { isVisible } from 'utils/utils';
import { useGetInitiativeMembersQuery, useGetInitiativesByIdQuery } from 'store/rtk-query/initiativesApi';

const NewsContainer = styled(Grid)(() => ({}));
export const StyledButton = styled(Button)(() => ({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '0.5rem',
  padding: '0px 16px'
}));

const NewsTab = ({ showCreateButton = true, showPrivacy = false }) => {
  const { initiativeId } = useParams();
  const navigate = useNavigate();
  const intl = useIntl();
  const { data: newsList, isLoading: isNewsLoading } = useGetAllNewsQuery(initiativeId, { skip: !initiativeId });
  const {
    data: { uuid: userId = '' }
  } = useGetUserInfoQuery();
  const { data: initiativeDetails } = useGetInitiativesByIdQuery(
    { userId, initiativeId },
    {
      skip: !initiativeId || !userId,
      refetchOnMountOrArgChange: true
    }
  );

  const { data: initiativeMembers } = useGetInitiativeMembersQuery(initiativeId, { skip: !initiativeId });
  const showButton = isVisible(initiativeDetails, initiativeMembers, userId);

  if (isNewsLoading) return Array.from(new Array(4)).map((_, index) => <InitiativeActivities key={index} />);

  return (
    <NewsContainer container>
      {showCreateButton && (
        <Grid item alignItems={'center'} mt={2} xs={12} display={'flex'} justifyContent={'flex-end'}>
          <StyledButton onClick={() => navigate(`/initiatives/${initiativeId}/contributions/add`)} variant="contained">
            {intl.formatMessage(messages.CreateNewsArticle)}
          </StyledButton>
        </Grid>
      )}
      {newsList?.length > 0 ? (
        newsList.map((newsItem, index) => {
          return (
            <NewsListing
              key={newsItem.id}
              newsItem={newsItem}
              index={index}
              initiativeId={initiativeId}
              showPrivacy={showPrivacy}
              userId={userId}
            />
          );
        })
      ) : (
        <Grid xs={12}>
          <NoMoreData
            title={intl.formatMessage(messages.NoNewsAvailable)}
            redirectLink={`/initiatives/${initiativeId}/contributions/add`}
            redirectButtonText={showButton && intl.formatMessage(messages.CreateFirstContribution)}
          />
        </Grid>
      )}
    </NewsContainer>
  );
};

export default NewsTab;

NewsTab.propTypes = {
  showCreateButton: PropTypes.bool,
  showPrivacy: PropTypes.bool
};
