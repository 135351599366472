import { Skeleton, Stack } from '@mui/material';
import { StyledComment } from 'components/InitiativeTabs/InitiativeReactionSection';
import React from 'react';

export const CommentLoader = () => {
  return Array.from(new Array(6)).map((item, index) => (
    <StyledComment key={index}>
      <Stack direction="row " gap={1} alignItems={'start'}>
        <Skeleton variant="circular" sx={{ width: 30, height: 30 }} />
      </Stack>
      <Stack sx={{ width: '90%' }} gap={0.5}>
        <Skeleton variant="rectangular" />
        <Skeleton variant="rectangular" />
      </Stack>
    </StyledComment>
  ));
};
