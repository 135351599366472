import { Chip } from '@mui/material';
import PropTypes from 'prop-types';
export const CustomChip = ({ label, variant, leftIcon, rightIcon, chipSx, className, ...others }) => {
  return (
    <Chip
      sx={{
        fontSize: '0.8rem',
        m: '0.2rem',
        borderRadius: '6px',
        maxLines: 1,
        bgcolor: 'secondary.cardBackground',
        fontWeight: 500,
        color: 'primary.main',
        height: 'auto',
        p: '3px 5px',
        mt: 1.5,
        width: 'max-content',
        ...chipSx
      }}
      label={label}
      variant={variant || 'filled'}
      icon={leftIcon}
      deleteIcon={rightIcon}
      onDelete={rightIcon ? () => {} : null}
      className={className}
      {...others}
    />
  );
};

CustomChip.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
  variant: PropTypes.string,
  leftIcon: PropTypes.node,
  others: PropTypes.object,
  chipSx: PropTypes.object,
  rightIcon: PropTypes.element,
  className: PropTypes.string
};
