export const ROLES_AVAILABLE = ['ADMIN', 'SUPER_ADMIN', 'AUDITOR', 'USER'];
export const licenseaTypeArr = ['personal', 'floating'];

export const ROLES = {
  ADMIN: ROLES_AVAILABLE[0],
  SUPER_ADMIN: ROLES_AVAILABLE[1],
  AUDITOR: ROLES_AVAILABLE[2],
  USER: ROLES_AVAILABLE[3]
};

export const PERSONA = [
  {
    id: 1,
    Persona_Name: 'Student'
  },
  {
    id: 2,
    Persona_Name: 'Private Expert'
  },
  {
    id: 3,
    Persona_Name: 'Corporate Personnels'
  },
  {
    id: 4,
    Persona_Name: 'University/Research Institute'
  },
  {
    id: 5,
    Persona_Name: 'Retired Expert'
  }
];

export const licenseTypeObj = {
  PERSONAL: licenseaTypeArr[0],
  FLOATING: licenseaTypeArr[1]
};

export const academicFieldOptions = ['Dr.', 'Prof.', 'PhD'];
