import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { IconSortDescending2 } from '@tabler/icons-react';
import MenuIconComponent from './MuiMenu';
import { colors } from 'utils/constants/colors';

const StyledTitleText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins',
  color: colors.black[500],
  fontSize: '1.5rem',
  fontWeight: 500,
  lineHeight: '110%', // Note: In sx, unitless line-heights are preferred
  textTransform: 'capitalize',
  [theme.breakpoints.down('msm')]: {
    fontSize: '0.75rem'
  }
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    padding: '6px 10px',
    fontSize: '0.8rem'
  },
  [theme.breakpoints.down('msm')]: {
    padding: '6px',
    fontSize: '0.65rem'
  }
}));

const FilteredText = styled(Typography)(({ theme }) => ({
  alignSelf: 'end',
  fontSize: '1rem',
  textDecoration: 'underline',
  [theme.breakpoints.down('msm')]: {
    fontSize: '0.75rem'
  }
}));

const HeaderTitleComponent = ({
  title,
  rightButtonText,
  isRightIconButtonShow = false,
  rightIconButton,
  onClick,
  filterSubText,
  options,
  sortingOptions,
  anchorEl,
  handleClick,
  handleClose,
  open,
  TabsComponent,
  rightFilterIcon,
  className = '',
  handleClickSort,
  handleCloseSort,
  anchorElSort,
  openSort,
  rightIconButtonSort,
  showSortIcon,
  showAscendingDescending,
  showAscendingDescendingIcon,
  openAscendingDescending,
  anchorElAscendingDescending,
  handleClickAscendingDescending,
  ascendingDescendingOptions,
  handleCloseAscendingDescending,
  filterSubTextSort,
  filterSubTextAscDesc
}) => {
  return (
    <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center', my: 1 }}>
      {title && <StyledTitleText>{title}</StyledTitleText>}
      <Stack direction="row">{TabsComponent && TabsComponent}</Stack>
      <Stack direction="row" alignItems="flex-end">
        {showSortIcon && (
          <Stack direction={'row'} mr={1}>
            {!!filterSubTextSort && <FilteredText variant="caption">{filterSubTextSort}</FilteredText>}
            <MenuIconComponent
              options={sortingOptions}
              anchorEl={anchorElSort}
              open={openSort}
              handleClick={handleClickSort}
              handleClose={handleCloseSort}
              renderIcon={rightIconButtonSort}
              customIconButton
              customAnchorPositionTop={33}
              customAnchorPositionLeft={40}
              popperWidth="22ch"
            />
          </Stack>
        )}
        {showAscendingDescending && (
          <Stack direction={'row'} mr={1}>
            {!!filterSubTextAscDesc && <FilteredText variant="caption">{filterSubTextAscDesc}</FilteredText>}
            <MenuIconComponent
              options={ascendingDescendingOptions}
              anchorEl={anchorElAscendingDescending}
              open={openAscendingDescending}
              handleClick={handleClickAscendingDescending}
              handleClose={handleCloseAscendingDescending}
              renderIcon={showAscendingDescendingIcon}
              customIconButton
              customAnchorPositionTop={33}
              customAnchorPositionLeft={40}
              popperWidth="22ch"
            />
          </Stack>
        )}
        {isRightIconButtonShow && (
          <Stack direction={'row'} mr={1}>
            {!!filterSubText && <FilteredText variant="caption">{filterSubText}</FilteredText>}
            <MenuIconComponent
              options={options}
              anchorEl={anchorEl}
              open={open}
              handleClick={handleClick}
              handleClose={handleClose}
              renderIcon={rightIconButton}
              customIconButton
              customAnchorPositionTop={33}
              customAnchorPositionLeft={40}
              popperWidth="22ch"
            />
          </Stack>
        )}
        <Stack direction={'row'} gap={1.5}>
          {rightFilterIcon && (
            <Box height="40px" width="40px">
              <IconButton
                sx={{
                  cursor: 'pointer',
                  backgroundColor: colors.info[30],
                  borderRadius: '8px'
                }}
                onClick={handleClick}
              >
                <IconSortDescending2 />
              </IconButton>
            </Box>
          )}
          {rightButtonText && (
            <StyledButton variant="contained" onClick={onClick} className={className}>
              {rightButtonText}
            </StyledButton>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

HeaderTitleComponent.propTypes = {
  title: PropTypes.string,
  rightButtonText: PropTypes.string,
  onClick: PropTypes.func,
  isRightIconButtonShow: PropTypes.bool,
  rightIconButton: PropTypes.node,
  filterSubText: PropTypes.string,
  options: PropTypes.array,
  handleClick: PropTypes.func,
  handleClose: PropTypes.func,
  anchorEl: PropTypes.node,
  open: PropTypes.bool,
  showTabs: PropTypes.bool,
  TabsComponent: PropTypes.element,
  rightFilterIcon: PropTypes.bool,
  className: PropTypes.string,
  showSortIcon: PropTypes.bool,
  handleClickSort: PropTypes.func,
  sortingOptions: PropTypes.array,
  handleCloseSort: PropTypes.func,
  anchorElSort: PropTypes.bool,
  openSort: PropTypes.bool,
  rightIconButtonSort: PropTypes.node,
  showAscendingDescending: PropTypes.bool,
  showAscendingDescendingIcon: PropTypes.node,
  openAscendingDescending: PropTypes.bool,
  anchorElAscendingDescending: PropTypes.bool,
  handleClickAscendingDescending: PropTypes.func,
  ascendingDescendingOptions: PropTypes.array,
  handleCloseAscendingDescending: PropTypes.func,
  filterSubTextSort: PropTypes.string,
  filterSubTextAscDesc: PropTypes.string
};
export default memo(HeaderTitleComponent);
