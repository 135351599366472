import { Stack, Tabs, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import { CountText } from 'pages/InitiativeHub/InitiativeOverView';
import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const StyledTabHeading = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '4px',
  [theme.breakpoints.down('lg')]: {
    svg: {
      width: 16,
      height: 16
    }
  }
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  justifyContent: 'flex-end',
  width: '100%',
  minHeight: 'inherit',
  '& .MuiButtonBase-root': {
    padding: '6px 20px'
  },
  '& .MuiTabs-flexContainer': {
    justifyContent: 'flex-end'
  },
  [theme.breakpoints.down('lg')]: {
    '& .MuiButtonBase-root': {
      padding: '6px 12px'
    },
    '& .MuiTabs-flexContainer': {
      justifyContent: 'end'
    }
  },
  [theme.breakpoints.down('sm')]: {
    '& .MuiButtonBase-root': {
      padding: '6px 10px',
      width: '20px'
    },
    a: {
      padding: theme.spacing(1)
    },
    '& .MuiTabs-flexContainer': {
      justifyContent: 'center'
    }
  }
}));

const StyledTabTitle = styled(Typography)(({ theme, disabled }) => ({
  color: disabled && theme.palette.dark.labelTextColor,
  [theme.breakpoints.down('sm')]: {
    fontSize: '10px'
  }
}));

const TabsComponent = ({ tabValue, data, redirectTo, tabsData, condition = true, disabledFields }) => {
  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down('lg'));

  const StyledTab = styled(Link)(({ value, disabled = false }) => ({
    border: `1px solid ${theme.palette.primary.light}`,
    // borderBottom: `1px solid ${theme.palette.primary.light}`,
    borderRadius: '20px 20px 0px 0px',
    padding: theme.spacing(1, 2),
    textDecoration: 'none',
    background: disabled ? theme.palette.grey[300] : value === tabValue ? theme.palette.primary.mainLight : 'inherit',
    color: disabled
      ? theme.palette.dark.labelTextColor
      : value === tabValue
      ? theme.palette.primary.contrastText
      : theme.palette.primary.main,
    '&:hover': {
      cursor: disabled ? 'not-allowed' : 'pointer',
      background: disabled ? theme.palette.grey[300] : theme.palette.primary.mainLight,
      color: disabled ? theme.palette.dark.labelTextColor : theme.palette.primary.contrastText
    },
    textTransform: 'capitalize'
  }));

  return (
    <StyledTabs
      selectionFollowsFocus
      variant="scrollable"
      scrollButtons="auto"
      allowScrollButtonsMobile
      TabIndicatorProps={{ style: { display: 'none' } }}
      value={tabValue}
      sx={{ position: 'sticky', top: '71px' }}
    >
      {data?.map((tab, index) => {
        const { icon, title, count } = tab;
        return (
          <StyledTab
            to={
              disabledFields || tabsData[condition ? index : index + 1]?.disabled
                ? ''
                : `${redirectTo}/${tabsData[condition ? index : index + 1]?.value}`
            }
            value={tabsData[condition ? index : index + 1]?.value}
            key={index}
            id={index}
            disabled={title === 'Details' ? false : disabledFields ? disabledFields : tabsData[condition ? index : index + 1]?.disabled}
          >
            <StyledTabHeading>
              {!downLg && cloneElement(icon)}
              <StyledTabTitle disabled={tabsData[condition ? index : index + 1]?.disabled}>{title}</StyledTabTitle>
              {!!count && <CountText disabled={tabsData[condition ? index : index + 1]?.disabled}>{count}</CountText>}
            </StyledTabHeading>
          </StyledTab>
        );
      })}
    </StyledTabs>
  );
};

TabsComponent.propTypes = {
  tabValue: PropTypes.string,
  data: PropTypes.array,
  redirectTo: PropTypes.string,
  value: PropTypes.string,
  tabsData: PropTypes.array,
  condition: PropTypes.bool,
  disabledFields: PropTypes.bool
};

export default TabsComponent;
