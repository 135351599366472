import React from 'react';
import { Box, Grid, Paper, Skeleton, Stack } from '@mui/material';

const InitiativeDetailsTab = () => {
  return (
    <Grid container>
      <Grid item xs={12} md={9} px={0.5} mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1rem' }}>
              <Skeleton variant="circular" width={50} height={50} />
              <Stack alignItems={'flex-start'} textAlign={'left'}>
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={11} md={7}>
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1rem' }}>
              <Skeleton variant="circular" width={50} height={50} />
              <Stack alignItems={'flex-start'} textAlign={'left'}>
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={1}>
            <Skeleton variant="circular" width={40} height={40} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Stack direction={'row'} flexWrap={'wrap'}>
              {Array.from(new Array(3)).map((_, i) => (
                <Skeleton variant="rectangular" sx={{ fontSize: '1rem' }} width={100} key={i} />
              ))}
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{ display: 'flex', flexDirection: 'row', gap: '10rem' }}>
              {Array.from(new Array(3)).map((_, i) => {
                return (
                  <Stack key={i} spacing={2} alignItems={'flex-start'}>
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
                  </Stack>
                );
              })}
            </Paper>
            <Box sx={{ wordBreak: 'break-word' }}>
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} height={300} />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InitiativeDetailsTab;
