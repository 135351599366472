import PropTypes from 'prop-types';

// project import
import Default from './default';
import Theme1 from './theme1';

// ==============================|| PRESET THEME - THEME SELECTOR ||============================== //

const Theme = (colors, presetColor, mode) => {
  switch (presetColor) {
    case 'theme1':
      return Theme1(colors, mode);
    default:
      return Default(colors);
  }
};

Theme.propTypes = {
  colors: PropTypes.object,
  presetColor: PropTypes.any
};

export default Theme;
