import React from 'react';
import PropTypes from 'prop-types';
import { IconThumbUp, IconMessage2Share, IconThumbUpFilled } from '@tabler/icons-react';
import { Stack, useTheme } from '@mui/material';
import IconButton from 'components/@common/IconButton';
import { CountText } from '../../pages/InitiativeHub/InitiativeOverView';
import { reactionType } from 'utils/constants/initiatives';

const ActionComponent = ({
  sxProps = {},
  gap,
  likeCount = 0,
  commentCount = 0,
  currentReaction,
  handleActionButton,
  disable = false,
  handleCommentActionButton,
  disableComment = false,
  isLiked
}) => {
  const { palette } = useTheme();

  return (
    <Stack direction="row" alignItems="center" gap={gap} sx={sxProps}>
      <IconButton onClick={() => handleActionButton(reactionType.LIKE)} disable={disable}>
        {currentReaction?.type === reactionType.LIKE || isLiked ? (
          <IconThumbUpFilled color={disable ? palette.grey[550] : palette.primary.mainLight} />
        ) : (
          <IconThumbUp color={disable ? palette.grey[550] : palette.primary.mainLight} />
        )}
      </IconButton>
      <CountText className="count-text">{likeCount}</CountText>
      <IconButton disable={disable || disableComment} onClick={handleCommentActionButton}>
        <IconMessage2Share color={disable ? palette.grey[550] : palette.primary.mainLight} />
      </IconButton>
      <CountText className="count-text">{commentCount}</CountText>
    </Stack>
  );
};

ActionComponent.propTypes = {
  sxProps: PropTypes.object,
  gap: PropTypes.number,
  likeCount: PropTypes.number,
  commentCount: PropTypes.number,
  currentReaction: PropTypes.object,
  handleActionButton: PropTypes.func,
  disable: PropTypes.bool,
  handleCommentActionButton: PropTypes.func,
  disableComment: PropTypes.bool,
  isLiked: PropTypes.bool
};

export default ActionComponent;
