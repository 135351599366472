import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import TooltipWrapper from './TooltipWrapper';

const OneLinerTypographyWithTooltip = ({ text, tooltipText, sxPropsBox, wordCount, lineCount = 1 }) => {
  return (
    <TooltipWrapper tooltipText={tooltipText} placement="left-start" offset={[0, -10]} wordCount={wordCount}>
      <Box
        sx={{
          display: '-webkit-box',
          WebkitLineClamp: lineCount,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          WebkitBoxOrient: 'vertical',
          wordBreak: 'break-word',
          ...sxPropsBox
        }}
      >
        {text}
      </Box>
    </TooltipWrapper>
  );
};

export default OneLinerTypographyWithTooltip;

OneLinerTypographyWithTooltip.propTypes = {
  text: PropTypes.string,
  tooltipText: PropTypes.string,
  sxPropsBox: PropTypes.object,
  wordCount: PropTypes.number,
  lineCount: PropTypes.number
};
