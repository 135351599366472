import { defineMessages } from 'react-intl';

export const scope = 'app.component.layout.mianLayout.Header.HeaderContent.HeaderIconComponent';

export default defineMessages({
  chat: {
    id: `${scope}.chat`,
    defaultMessage: 'Chat'
  },
  feedback: {
    id: `${scope}.feedback`,
    defaultMessage: 'Feedback'
  },
  notification: {
    id: `${scope}.notification`,
    defaultMessage: 'Notification'
  },
  you: {
    id: `${scope}.you`,
    defaultMessage: 'You'
  },
  showProfile: {
    id: `${scope}.showProfile`,
    defaultMessage: 'Show Profile'
  },
  licenseAdministration: {
    id: `${scope}.licenseAdministration`,
    defaultMessage: 'License Administration'
  },
  changePassword: {
    id: `${scope}.changePassword`,
    defaultMessage: 'Change Password'
  },
  userManagement: {
    id: `${scope}.userManagement`,
    defaultMessage: 'User Management'
  },
  enterpriseManagement: {
    id: `${scope}.enterpriseManagement`,
    defaultMessage: 'Enterprise Management'
  },
  SuperAdminPanel: {
    id: `${scope}.SuperAdminPanel`,
    defaultMessage: 'Super Admin Panel'
  },
  logout: {
    id: `${scope}.logout`,
    defaultMessage: 'Log out'
  },
  appTourGuide: {
    id: `${scope}.appTourGuide`,
    defaultMessage: 'App Tour Guide'
  },
  passwordChangeTitle: {
    id: `${scope}.passwordChangeTitle`,
    defaultMessage: 'Change Password'
  },
  passwordChangeButtonText: {
    id: `${scope}.passwordChangeButtonText`,
    defaultMessage: 'Update Password'
  },
  passwordChangeOldPassLabel: {
    id: `${scope}.passwordChangeButtonText`,
    defaultMessage: 'Current Password'
  },
  passwordChangeOldPassPlaceHolder: {
    id: `${scope}.passwordChangeOldPassPlaceHolder`,
    defaultMessage: 'Enter your current password'
  },
  passwordChangeNewPassLabel: {
    id: `${scope}.passwordChangeNewPassLabel`,
    defaultMessage: 'New Password'
  },
  passwordChangeNewPassPlaceHolder: {
    id: `${scope}.passwordChangeNewPassPlaceHolder`,
    defaultMessage: 'Enter a new password'
  },
  passwordChangeConfirmPassLabel: {
    id: `${scope}.passwordChangeConfirmPassLabel`,
    defaultMessage: 'Confirm New Password'
  },
  passwordChangeConfirmPassPlaceHolder: {
    id: `${scope}.passwordChangeConfirmPassPlaceHolder`,
    defaultMessage: 'Re-enter the new password'
  },
  SearchInInnofuse: {
    id: `${scope}.SearchInInnofuse`,
    defaultMessage: 'Search in Innofuse'
  },
  SearchInReactor: {
    id: `${scope}.SearchInInnofuse`,
    defaultMessage: 'Search in Reactor'
  }
});
