export const LoginFields = [
  {
    name: 'email',
    placeholderMessageKey: 'emailPlaceholder',
    label: 'email',
    type: 'email'
  },
  {
    name: 'password',
    placeholderMessageKey: 'passwordPlaceholder',
    label: 'password',
    type: 'password'
  }
];

export const setPasswordFields = [
  {
    name: 'password',
    placeholderMessageKey: 'newPasswordPlaceholder',
    label: 'newPassword',
    type: 'password'
  },
  {
    name: 'confirmPassword',
    placeholderMessageKey: 'confirmPasswordPlaceholder',
    label: 'confirmPassword',
    type: 'password'
  }
];
