import { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Box, Container, Stack, Dialog, IconButton, Link, Typography } from '@mui/material';
import Loader from 'components/Progress/Loader';
import { IconX, IconCheckupList } from '@tabler/icons-react';
import TourComponent from 'components/@common/TourComponent';
import Drawer from './Drawer';
import Header from './Header';
import HeaderIconComponent from './Header/HeaderContent/HeaderIconComponent';
import useChatWebSocket from 'hooks/useChatWebSocket';
import paths from 'utils/constants/paths';

const MainLayout = ({ withOutContainer }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [openPDF, setOpenPDF] = useState(false);
  const handleDrawerToggle = (id) => {
    if (id === paths.TermsAndPolicies.pathTitle) {
      setOpenPDF(true);
    }
    setOpen(!open);
  };
  const [socket, messages, setMessages, data, selectedUser, setSelectedUser, isLoading, userStatus] = useChatWebSocket();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { pathname } = useLocation();
  const onChatWindow = pathname.includes(`chat`);

  if (isLoading) return <Loader />;

  return (
    <Box sx={{ display: 'flex', position: 'relative', flexGrow: 1, height: '100vh' }}>
      <Header open={open} handleDrawerToggle={handleDrawerToggle} />
      <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
      <Dialog open={openPDF} onClose={() => setOpenPDF(false)} fullWidth>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Typography fontWeight={600} sx={{ padding: '10px', fontSize: '18px' }}>
            {paths.TermsAndPolicies.pathTitle}
          </Typography>
          <IconButton
            onClick={() => setOpenPDF(false)}
            sx={{
              color: theme.palette.grey[500]
            }}
          >
            <IconX />
          </IconButton>
        </Stack>
        <Stack p={1.25}>
          <Link component="a" target="_blank" href="https://innofuse.swiss/general_provisions.pdf" rel="noopener">
            General Provisions
          </Link>
          <Link component="a" target="_blank" href="https://innofuse.swiss/playrules.pdf" rel="noopener">
            Innofuse Framework Play Rules
          </Link>
          <Link component="a" target="_blank" href="https://innofuse.swiss/privacy_policy_cookies.pdf" rel="noopener">
            Privacy Policies
          </Link>
          <Link component="a" target="_blank" href="https://innofuse.swiss/nda.pdf" rel="noopener">
            Confidentiality and Non-Disclosure Agreement
          </Link>
        </Stack>
      </Dialog>
      <Box
        component="main"
        sx={{ flexGrow: 1, position: 'relative', marginTop: '83px', marginBottom: { xs: '70px', sm: 0 }, width: 'calc(100% - 260px)' }}
      >
        {!isMobile && (
          <IconButton
            gap={0.25}
            sx={{
              position: 'fixed',
              bottom: '3%',
              right: '1%',
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',
              borderRadius: '50%',
              padding: '5px',
              alignItems: 'center',
              bgcolor: 'secondary.cardBackground',
              zIndex: 1
            }}
            target="_blank"
            href="https://innofuse.swiss/playrules.pdf"
            rel="noopener"
            aria-label="link"
          >
            <IconCheckupList color={theme.palette.primary.main} size={30} stroke={1.2} />
          </IconButton>
        )}
        <TourComponent />
        {withOutContainer ? (
          <Outlet context={{ socket, messages, setMessages, data, selectedUser, setSelectedUser, userStatus }} />
        ) : (
          <Container
            maxWidth="xl"
            sx={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Outlet context={{ setSelectedUser }} />
          </Container>
        )}
      </Box>
      {isMobile && !onChatWindow && (
        <Box sx={{ position: 'fixed', bottom: 0, width: '100%', zIndex: 1101, boxShadow: '0px 4px 15px 0px rgba(46, 108, 164, 0.15)' }}>
          <Stack
            direction="row"
            sx={{ backgroundColor: theme.palette.secondary.cardBackground, justifyContent: 'space-evenly', height: '70px' }}
          >
            <HeaderIconComponent />
          </Stack>
        </Box>
      )}
    </Box>
  );
};

MainLayout.propTypes = {
  withOutContainer: PropTypes.bool
};

export default MainLayout;
