import { toast, Toaster, ToastBar } from 'react-hot-toast';
import { IconButton } from '@mui/material';
import { IconSquareRoundedX } from '@tabler/icons-react';
import { colors } from 'utils/constants/colors';

const ReactHotToaster = () => {
  return (
    <Toaster
      position="top-right"
      toastOptions={{ duration: 3000 }} // default duration 3 seconds
      reverseOrder={false}
    >
      {(t) => (
        <ToastBar
          toast={t}
          style={{
            ...t.style
          }}
        >
          {({ icon, message }) => (
            <>
              {icon}
              {message}
              {t.type !== 'loading' && (
                <IconButton onClick={() => toast.dismiss(t.id)}>
                  <IconSquareRoundedX color={colors.error[400]} />
                </IconButton>
              )}
              {/* {t.type !== 'loading' && <button onClick={() => toast.dismiss(t.id)}>X</button>} */}
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
};

export default ReactHotToaster;
