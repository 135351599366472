import PropTypes from 'prop-types';

const CustomTabPanel = ({ children, value, index, sx, ...other }) => {
  return (
    <div hidden={value !== index} {...other} style={{ overflowY: 'auto', height: '75vh', scrollbarWidth: 'none', ...sx }}>
      {value === index && children}
    </div>
  );
};

export default CustomTabPanel;

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  sx: PropTypes.object
};
