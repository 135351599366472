import { createFilterOptions } from '@mui/material/Autocomplete';
import removeUnderScore from './removeUnderscore';
import addUnderScore from './addUnderscore';
import { visiblityItemsObj } from 'utils/constants/initiatives';

export const filter = createFilterOptions();
export const handleSearch = (func, value) => {
  func(value);
};
export const handleVisibilityChange = (values, name, id, checked, push, replace) => {
  const existingItemIndex = values.initiativeVisibility.findIndex((val) => val.fieldName === name);
  const newVisibilityItem = { ...(id && { id }), fieldName: name, isVisible: checked };
  if (existingItemIndex > -1) {
    replace(existingItemIndex, newVisibilityItem);
  } else {
    push(newVisibilityItem);
  }
};

export const handleInitiativeVisibilityFormik = (event, newValue, setFieldValue) => {
  const setVisibility = (isVisible) => {
    setFieldValue('initiativeVisibility', [
      { fieldName: 'title', isVisible },
      { fieldName: 'userInfo', isVisible },
      { fieldName: 'organization', isVisible },
      { fieldName: 'teaser', isVisible },
      { fieldName: 'keywords', isVisible },
      { fieldName: 'administrators', isVisible }
    ]);
  };

  switch (newValue) {
    case visiblityItemsObj.PRIVATE:
      setVisibility(false);
      break;
    case visiblityItemsObj.PUBLIC:
      setVisibility(true);
      break;
    case removeUnderScore(visiblityItemsObj.PLATFORM_MEMBERS):
      setVisibility(true);
      break;
    default:
      break;
  }

  setFieldValue('visibility', newValue === removeUnderScore(visiblityItemsObj.PLATFORM_MEMBERS) ? addUnderScore(newValue) : newValue);
};
