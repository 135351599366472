import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { AppBar, Toolbar, styled } from '@mui/material';
import HeaderContent from './HeaderContent';

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  boxShadow: '0px 4px 15px 0px rgba(46, 108, 164, 0.15)',
  display: 'flex',
  zIndex: 1101
}));

const StyledToolBar = styled(Toolbar)(({ theme }) => ({
  paddingBottom: theme.spacing(1)
}));

const Header = ({ handleDrawerToggle }) => {
  const headerContent = useMemo(() => <HeaderContent handleDrawerToggle={handleDrawerToggle} />, [handleDrawerToggle]);
  return (
    <StyledAppBar position="fixed" color="inherit" elevation={0}>
      <StyledToolBar>{headerContent}</StyledToolBar>
    </StyledAppBar>
  );
};

Header.propTypes = {
  handleDrawerToggle: PropTypes.func
};

export default Header;
