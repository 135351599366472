import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Grid, Stack, Typography } from '@mui/material';
import paths from 'utils/constants/paths';
import { colors } from 'utils/constants/colors';

const InsightBox = ({ renderElement, title, redirectButtonText, description, redirectLink = paths.Root.url, onButtonClick }) => {
  return (
    <Grid container spacing={10} direction="column" alignItems="center" justifyContent="center" sx={{ pt: 1.5, pb: 1, overflow: 'hidden' }}>
      <Grid item xs={12}>
        {renderElement}
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={2} justifyContent="center" alignItems="center">
          <Typography color={colors.default} variant="h1">
            {title}
          </Typography>
          <Typography color="textSecondary" textAlign="center" width={'100%'}>
            {description}
          </Typography>
          {redirectButtonText && (
            <Button
              component={Link}
              onClick={onButtonClick}
              to={redirectLink}
              sx={{ backgroundColor: colors.default, color: 'white' }}
              variant="contained"
            >
              {redirectButtonText}
            </Button>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default InsightBox;

InsightBox.propTypes = {
  redirectLink: PropTypes.string,
  renderElement: PropTypes.node,
  title: PropTypes.string,
  redirectButtonText: PropTypes.string,
  description: PropTypes.string,
  onButtonClick: PropTypes.func
};
