/* eslint-disable react/prop-types */
import { Grid, Skeleton, Stack, Typography } from '@mui/material';
import React from 'react';
import { colors } from 'utils/constants/colors';

const AvatarNameWithButtonSkeleton = ({ recommendedUserTitle, recommendedOrganizationTitle }) => {
  return (
    <>
      <Typography pt={1.5} pb={0.5} variant="body2" color={colors.dark[550]}>
        {recommendedUserTitle}
      </Typography>
      <Grid gap={2} container spacing={1} justifyContent={'flex-start'} pt={1} pb={2}>
        {[1, 2, 3].map((item) => (
          <Stack key={item} direction={'row'} width={'100%'} gap={1}>
            <Skeleton sx={{ width: 40, height: 40 }} variant="circular" height={40} />
            <Stack width={'100%'} gap={1}>
              <Skeleton variant="rectangular" height={30} width={'90%'} />
              <Skeleton variant="rectangular" height={10} width={'50%'} />
            </Stack>
          </Stack>
        ))}
      </Grid>
      <Typography pt={1.5} pb={0.5} variant="body2" color={colors.dark[550]}>
        {recommendedOrganizationTitle}
      </Typography>
      <Grid container spacing={1} justifyContent={'flex-start'} pt={1} pb={2}>
        <Grid gap={2} container spacing={1} justifyContent={'flex-start'} pt={1} pb={2}>
          {[1, 2, 3].map((item) => (
            <Stack key={item} direction={'row'} width={'100%'} gap={1}>
              <Skeleton sx={{ width: 40, height: 40 }} variant="circular" height={40} />
              <Stack width={'100%'} gap={1}>
                <Skeleton variant="rectangular" height={30} width={'90%'} />
                <Skeleton variant="rectangular" height={10} width={'50%'} />
              </Stack>
            </Stack>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default AvatarNameWithButtonSkeleton;
