import { defineMessages } from 'react-intl';

export const scope = 'app.component.initiativestabs';

export default defineMessages({
  Administors: {
    id: `${scope}.Administrators`,
    defaultMessage: 'Administrator’s'
  },
  Keywords: {
    id: `${scope}.Keywords`,
    defaultMessage: 'Keywords'
  },
  Owner: {
    id: `${scope}.Owner`,
    defaultMessage: 'Owner'
  },
  CreateNewsArticle: {
    id: `${scope}.CreateNewsArticle`,
    defaultMessage: 'Contribute to Initiative'
  },
  CreateFirstContribution: {
    id: `${scope}.CreateFirstContribution`,
    defaultMessage: 'Create First Contribution'
  },
  ComingSoon: {
    id: `${scope}.ComingSoon`,
    defaultMessage: 'Coming Soon'
  },
  ThanksForSharingInterestInTrainings: {
    id: `${scope}.ThanksForSharingInterestInTrainings`,
    defaultMessage: 'Thanks for sharing your interest in trainings! We will notify you as soon as we are ready with the trainings.'
  },
  OnlyMembersCanLinkInitiative: {
    id: `${scope}.OnlyMembersCanLinkInitiative`,
    defaultMessage: 'Only members can link an initiative'
  },
  Share: {
    id: `${scope}.Share`,
    defaultMessage: 'Share'
  },
  Expertise: {
    id: `${scope}.Expertise`,
    defaultMessage: 'Expertise'
  },
  NoExpertiseAvailable: {
    id: `${scope}.NoExpertiseAvailable`,
    defaultMessage: 'Not available'
  },
  EditComment: {
    id: `${scope}.EditComment`,
    defaultMessage: 'Edit comment'
  },
  DeleteComment: {
    id: `${scope}.DeleteComment`,
    defaultMessage: 'Delete comment'
  },
  Comments: {
    id: `${scope}.Comments`,
    defaultMessage: 'Comments'
  },
  NoNewsAvailable: {
    id: `${scope}.NoNewsAvailable`,
    defaultMessage: 'No Contributions Yet'
  },
  NoMembersYetInInitiatives: {
    id: `${scope}.NoMembersYetInInitiatives`,
    defaultMessage: 'No members added yet in the initiatives'
  },
  NoNetworkYet: {
    id: `${scope}.NoNetworkYet`,
    defaultMessage: 'No Network Yet'
  },
  BackToInitiaitive: {
    id: `${scope}.BackToInitiaitive`,
    defaultMessage: 'Back To Initiative'
  },
  InitiativeLiteratureWillShowHere: {
    id: `${scope}.InitiativeLiteratureWillShowHere`,
    defaultMessage: 'Initiatives Literature will show here'
  },
  NoActivitiesFound: {
    id: `${scope}.NoActivitiesFound`,
    defaultMessage: 'No Activities Found'
  },
  NoActivitiesFoundDescription: {
    id: `${scope}.NoActivitiesFoundDescription`,
    defaultMessage: 'There are no activities for this initiative'
  },
  NoMoreDataToLoad: {
    id: `${scope}.NoMoreDataToLoad`,
    defaultMessage: 'No Data To Show'
  },
  LinkedInitiative: {
    id: `${scope}.LinkedInitiative`,
    defaultMessage: 'Link Initiative'
  },
  RelatedInitiative: {
    id: `${scope}.RelatedInitiative`,
    defaultMessage: 'Related Initiative'
  },
  NoLinkedInitiativeYet: {
    id: `${scope}.NoLinkedInitiativeYet`,
    defaultMessage: 'No Linked Initiative Yet'
  },
  NoSimilarInitiativeYet: {
    id: `${scope}.NoSimilarInitiativeYet`,
    defaultMessage: 'No Related Initiative Yet'
  },
  NoLinkedInitiativeFound: {
    id: `${scope}.NoLinkedInitiativeFound`,
    defaultMessage: 'There are no linked initiatives found for this initiative'
  },
  NoSimilarInitiativeFound: {
    id: `${scope}.NoSimilarInitiativeFound`,
    defaultMessage: 'There are no related initiatives found for this initiative'
  },
  LeaveInitiative: {
    id: `${scope}.LeaveInitiative`,
    defaultMessage: 'Leave initiative'
  },
  ContactInitiativeAdmin: {
    id: `${scope}.ContactInitiativeAdmin`,
    defaultMessage: 'Contact initiative admin'
  },
  AddToWatchList: {
    id: `${scope}.AddToWatchList`,
    defaultMessage: 'Add to watch list'
  },
  RemoveFromWatchList: {
    id: `${scope}.RemoveFromWatchList`,
    defaultMessage: 'Remove from watch list'
  },
  MessageLabelForOwner: {
    id: `${scope}.MessageLabelForOwner`,
    defaultMessage: 'Message'
  },
  WriteMessageForAdmin: {
    id: `${scope}.WriteMessageForAdmin`,
    defaultMessage: 'Write message for admin'
  }
});
