import { Tooltip } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

function TooltipWrapper({ children, tooltipText, placement, offset, wordCount = 10 }) {
  const wordCountLength = tooltipText?.split(/\s+/).filter((word) => word.trim() !== '').length;
  const charCount = tooltipText?.length;
  const showTooltip = wordCountLength >= wordCount || charCount >= 100;

  return (
    <>
      {showTooltip && (
        <Tooltip
          title={tooltipText}
          placement={placement}
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: offset || [0, -12]
                  }
                }
              ]
            }
          }}
        >
          {children}
        </Tooltip>
      )}
      {!showTooltip && children}
    </>
  );
}

TooltipWrapper.propTypes = {
  children: PropTypes.element,
  tooltipText: PropTypes.string,
  placement: PropTypes.oneOf([
    'top',
    'top-start',
    'top-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'left',
    'left-start',
    'left-end',
    'right',
    'right-start',
    'right-end'
  ]),
  offset: PropTypes.arrayOf(PropTypes.number),
  wordCount: PropTypes.number
};

export default TooltipWrapper;
