import { Tooltip } from '@mui/material';
import { IconInfoCircle } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import { colors } from 'utils/constants/colors';

const TextFieldTooltip = ({ title, withoutCheckBox = false }) => (
  <Tooltip title={title} placement="top-end">
    <IconInfoCircle size={30} color={colors.default} style={{ marginTop: withoutCheckBox ? '-10px' : '25px' }} stroke={1.5} />
  </Tooltip>
);

TextFieldTooltip.propTypes = {
  title: PropTypes.string,
  withoutCheckBox: PropTypes.bool
};

export default TextFieldTooltip;
