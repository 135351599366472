import { useState, useRef, useLayoutEffect } from 'react';

const useElementWidth = () => {
  const [elementWidth, setElementWidth] = useState(0);
  const [elementHeight, setElementHeight] = useState(0);

  const elementRef = useRef();

  useLayoutEffect(() => {
    const updateWidth = () => {
      if (elementRef.current) {
        setElementWidth(elementRef.current?.clientWidth);
        setElementHeight(elementRef.current?.clientHeight);
      }
    };

    updateWidth();

    const handleResize = () => {
      updateWidth();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { elementWidth, elementHeight, elementRef };
};

export default useElementWidth;
