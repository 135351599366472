import { createApi } from '@reduxjs/toolkit/query/react';
import queryTags from 'utils/constants/queryTags';
import queryEndPoints from 'utils/queryEndPoints';
import { RtkBaseQuery } from 'utils/request';

export const chatApi = createApi({
  reducerPath: 'chatApi',
  baseQuery: RtkBaseQuery,
  tagTypes: [queryTags.chatByUser, queryTags.recentChatUserList, queryTags.messagesCount],
  endpoints: (builder) => ({
    getChatByUserId: builder.query({
      query: ({ senderId, receiverId, offset, limit }) => queryEndPoints.getChatByUserId(senderId, receiverId, offset, limit),
      transformResponse: (response) => response?.data?.data,
      serializeQueryArgs: ({ endpointName }) => endpointName,
      merge: (currentCache, newItems, { arg }) => {
        const { offset } = arg;
        if (offset == 0) {
          currentCache.resultList = newItems.resultList;
          currentCache.totalCount = newItems.totalCount;
          return;
        }
        currentCache.resultList = [...newItems.resultList, ...currentCache.resultList];
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        // checking if query arguments has changed
        const checkRefetchCondition =
          currentArg.limit !== previousArg?.limit ||
          currentArg.offset !== previousArg?.offset ||
          currentArg?.senderId !== previousArg?.senderId ||
          currentArg?.receiverId !== previousArg?.receiverId;
        return checkRefetchCondition;
      },
      providesTags: [queryTags.chatByUser]
    }),
    recentChatUserList: builder.query({
      query: () => queryEndPoints.recentChatUserList,
      transformResponse: (response) => response?.data?.data,
      providesTags: [queryTags.recentChatUserList]
    }),
    messagesCount: builder.query({
      query: () => queryEndPoints.messagesCount,
      transformResponse: (response) => response?.data,
      providesTags: [queryTags.messagesCount]
    }),
    getMessagesById: builder.query({
      query: (id) => queryEndPoints.getMessagesById(id),
      transformResponse: (response) => response?.data,
      providesTags: [queryTags.getMessagesById]
    })
  })
});

export const { useGetChatByUserIdQuery, useMessagesCountQuery, useRecentChatUserListQuery, useLazyGetMessagesByIdQuery } = chatApi;
