import { Box, Stack, styled, Typography, useTheme } from '@mui/material';
import { IconArmchair, IconChartCircles, IconUsersGroup } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import React from 'react';
import { colors } from 'utils/constants/colors';
import globalConstant from 'utils/constants/globalConstant';

export const StaticLabel = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: '0.9rem',
  color: colors.dark[550],
  [theme.breakpoints.down('msm')]: {
    fontSize: '0.75rem'
  }
}));

const InitiativeDetailLabel = ({ label1, label2, type }) => {
  const { palette } = useTheme();
  return (
    <Stack direction="row" alignItems={'center'}>
      <StaticLabel mr={0.5} variant="h6">
        {label1}:
      </StaticLabel>
      <Typography color={colors.dark[550]} sx={{ ml: 0.5 }} fontSize={{ xs: '0.75rem', msm: '0.9rem' }}>
        {label2 || 'Anonymous'}
      </Typography>
      {type === globalConstant.COMMUNITY ? (
        <Box display={'flex'} pl={0.5}>
          <IconUsersGroup stroke={1.5} color={palette.primary.main} size={18} />
        </Box>
      ) : type === globalConstant.CAMPAIGN ? (
        <Box display={'flex'} pl={0.5}>
          <IconChartCircles stroke={1.5} color={palette.primary.main} size={18} />
        </Box>
      ) : type === globalConstant.EVENT ? (
        <Box display={'flex'} pl={0.5}>
          <IconArmchair stroke={1.5} color={palette.primary.main} size={18} />
        </Box>
      ) : null}
    </Stack>
  );
};

InitiativeDetailLabel.propTypes = {
  label1: PropTypes.string,
  label2: PropTypes.string,
  type: PropTypes.string
};

export default InitiativeDetailLabel;
