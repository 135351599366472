import { IconNotebook, IconClearAll, IconBrandFeedly, IconFileCheck, IconFriends, IconSchool } from '@tabler/icons-react';

export const fieldConfig = {
  details: {
    id: 1,
    title: 'Details',
    icon: <IconNotebook />,
    count: 0
  },
  activities: {
    id: 2,
    title: 'Activities',
    icon: <IconClearAll />,
    count: 0
  },
  contributions: {
    id: 3,
    title: 'Contributions',
    icon: <IconBrandFeedly />,
    count: 0
  },
  literatures: {
    id: 4,
    title: 'Literature',
    icon: <IconFileCheck />,
    count: 0
  },
  network: {
    id: 5,
    title: 'Network',
    icon: <IconFriends />,
    count: 0
  },
  linkedInitiatives: {
    id: 6,
    title: 'Initiatives',
    icon: <IconNotebook />,
    count: 0
  },
  trainings: {
    id: 7,
    title: 'Trainings',
    icon: <IconSchool />,
    count: 0
  }
};
