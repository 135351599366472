import { defineMessages } from 'react-intl';

export const scope = 'app.component.maintenance';

export default defineMessages({
  PageNotFoundDescription: {
    id: `${scope}.PageNotFoundDescription`,
    defaultMessage: 'Page Not Found, page you are looking was moved, removed, renamed, or might never exist!'
  }
});
