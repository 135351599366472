import { Avatar, Box, Fab, useMediaQuery, useTheme, CircularProgress } from '@mui/material';
import { IconCamera } from '@tabler/icons-react';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { colors } from 'utils/constants/colors';

const ProfilePicUploader = ({
  handleUpload,
  profilePic,
  iconToRender,
  isUploading,
  showIcon = false,
  value = 0,
  className = 'profile-image'
}) => {
  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down('lg'));
  const inBetweenXsToSm = useMediaQuery(theme.breakpoints.between('xs', 'sm'));

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
      <Box sx={{ position: 'relative' }}>
        {!showIcon && (
          <Box sx={{ position: 'relative', width: 'fit-content' }}>
            <CircularProgress
              variant="determinate"
              value={value}
              sx={{
                position: 'absolute',
                top: '0%',
                left: '0%',
                zIndex: 1,
                width: { xs: '9.29rem !important', lg: '12.5rem !important' }, //198px
                height: { xs: '9.29rem !important', lg: '12.5rem !important' }
              }}
              color="success"
              thickness={2}
            />
            <Avatar
              src={profilePic || ''}
              alt="profile-pic"
              sx={{
                width: { xs: '9.375rem', lg: '12.5rem' },
                height: { xs: '9.375rem', lg: '12.5rem' },
                border: `4px solid ${colors.default}`,
                boxSizing: 'border-box',
                cursor: 'pointer',
                borderWidth: { xs: '8px', lg: '10px' },
                borderColor: theme.palette.secondary.cardBackground
              }}
            />
          </Box>
        )}
        {showIcon && (
          <>
            <Avatar
              src={profilePic || ''}
              alt="profile-pic"
              sx={{
                width: { xs: '9.375rem', lg: '12.5rem' },
                height: { xs: '9.375rem', lg: '12.5rem' },
                border: `4px solid ${colors.default}`,
                boxSizing: 'border-box',
                cursor: 'pointer'
              }}
            />
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="contained-button-file"
              type="file"
              onChange={handleUpload}
              alt="user-profile"
            />
            <label htmlFor="contained-button-file">
              <Fab
                size={downLg ? 'small' : 'medium'}
                handleUpload={handleUpload}
                component="span"
                sx={{
                  border: '0.1rem solid grey',
                  position: 'absolute',
                  top: inBetweenXsToSm ? -12 : 0,
                  margin: inBetweenXsToSm ? '1rem' : '0.4rem',
                  right: 0,
                  bgcolor: 'white'
                }}
                disabled={isUploading}
                className={className}
              >
                {isUploading ? <CircularProgress /> : iconToRender || <IconCamera size={downLg ? '1.5rem' : '2rem'} />}
              </Fab>
            </label>
          </>
        )}
      </Box>
    </Box>
  );
};

export default memo(ProfilePicUploader);

ProfilePicUploader.propTypes = {
  handleUpload: PropTypes.func,
  profilePic: PropTypes.any,
  iconToRender: PropTypes.node,
  isUploading: PropTypes.bool,
  showIcon: PropTypes.bool,
  value: PropTypes.number,
  className: PropTypes.string
};
