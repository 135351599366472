import { defineMessages } from 'react-intl';

export const scope = 'app.pages.addProject';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Add new Initiative'
  },
  updateTitle: {
    id: `${scope}.updateTitle`,
    defaultMessage: 'Update Initiative'
  },
  addPeople: {
    id: `${scope}.addPeople`,
    defaultMessage: 'Add People'
  },
  communityTitle: {
    id: 'cardDetails.community.title',
    defaultMessage: 'Community'
  },
  communityDescription: {
    id: 'cardDetails.community.description',
    defaultMessage: 'Stay connected and engaged: your community hub, always open for interaction.'
  },
  communityOption1Label: {
    id: 'cardDetails.community.option1.label',
    defaultMessage: 'Community of Practice (CoP)'
  },
  communityOption1Description: {
    id: 'cardDetails.community.option1.description',
    defaultMessage: 'A community space about a certain topic to organize symposiums and other events and stay digitally in contact'
  },
  communityOption2Label: {
    id: 'cardDetails.community.option2.label',
    defaultMessage: 'Department Community'
  },
  communityOption2Description: {
    id: 'cardDetails.community.option2.description',
    defaultMessage: 'A space for one specific Department (in a company or university)'
  },
  communityOption3Label: {
    id: 'cardDetails.community.option3.label',
    defaultMessage: 'Other'
  },
  communityOption3Description: {
    id: 'cardDetails.community.option3.description',
    defaultMessage: `Choose 'Other' if none of the above categories matches your purpose`
  },
  campaignTitle: {
    id: 'cardDetails.campaign.title',
    defaultMessage: 'Campaign'
  },
  campaignDescription: {
    id: 'cardDetails.campaign.description',
    defaultMessage:
      'Achieve your goals with a focused effort and a clear deadline. Solve problems and drive results within a set timeframe.'
  },
  campaignOption1Label: {
    id: 'cardDetails.campaign.option1.label',
    defaultMessage: 'Problem Solving'
  },
  campaignOption1Description: {
    id: 'cardDetails.campaign.option1.description',
    defaultMessage: 'A campaign to solve problems, resolve challenges in your daily life'
  },
  campaignOption2Label: {
    id: 'cardDetails.campaign.option2.label',
    defaultMessage: 'Science Call'
  },
  campaignOption2Description: {
    id: 'cardDetails.campaign.option2.description',
    defaultMessage: 'A campaign to find partners in a specific topics, let researchers and innovators propose a solution'
  },
  campaignOption3Label: {
    id: 'cardDetails.campaign.option3.label',
    defaultMessage: 'Other'
  },
  campaignOption3Description: {
    id: 'cardDetails.campaign.option3.description',
    defaultMessage: `Choose 'Other' if none of the above categories matches your purpose`
  },
  eventTitle: {
    id: 'cardDetails.event.title',
    defaultMessage: 'Event'
  },
  eventDescription: {
    id: 'cardDetails.event.description',
    defaultMessage: 'Mark your calendar for scheduled experiences, single or multi-day'
  },
  eventOption1Label: {
    id: 'cardDetails.event.option1.label',
    defaultMessage: 'Brainstorming Session'
  },
  eventOption1Description: {
    id: 'cardDetails.event.option1.description',
    defaultMessage: 'An event to generate ideas, foster creativity and innovation by putting diverse heads together and brainstorm'
  },
  eventOption2Label: {
    id: 'cardDetails.event.option2.label',
    defaultMessage: 'Workshop'
  },
  eventOption2Description: {
    id: 'cardDetails.event.option2.description',
    defaultMessage: `A 'workshop' is a focused session where participants actively engage in hands-on learning, skill development, or problem-solving activities related to a specific topic or subject.`
  },
  eventOption3Label: {
    id: 'cardDetails.event.option3.label',
    defaultMessage: 'Social Event'
  },
  eventOption3Description: {
    id: 'cardDetails.event.option3.description',
    defaultMessage: `A 'social event' is a gathering where individuals come together for networking, or celebration outside of pure formal or work-related contexts.`
  },
  eventOption4Label: {
    id: 'cardDetails.event.option4.label',
    defaultMessage: 'Other'
  },
  eventOption4Description: {
    id: 'cardDetails.event.option4.description',
    defaultMessage: `Choose 'Other' if none of the above categories matches your purpose`
  },
  AddedInitiativesSuccessfully: {
    id: `${scope}.AddedInitiativesSuccessfully`,
    defaultMessage: 'Initiatives Added Successfully !'
  },
  UpdatedInitiativesSuccessfully: {
    id: `${scope}.UpdatedInitiativesSuccessfully`,
    defaultMessage: 'Initiatives Updated Successfully !'
  },
  DraftInitiativesSuccessfully: {
    id: `${scope}.DraftInitiativesSuccessfully`,
    defaultMessage: 'Initiative Draft successfully !'
  },
  addPeopleLabel: {
    id: `${scope}.addPeopleLabel`,
    defaultMessage: 'Add People'
  },
  addPeoplePlaceholder: {
    id: `${scope}.addPeoplePlaceholder`,
    defaultMessage: 'Add Network'
  },
  InviteNetwork: {
    id: `${scope}.InviteNetwork`,
    defaultMessage: 'Invite Network'
  },
  requestSent: {
    id: `${scope}.requestSent`,
    defaultMessage: 'Request sent'
  },
  recommendedUserTitle: {
    id: `${scope}.recommendedUserTitle`,
    defaultMessage: 'Recommended users related to this project'
  },
  recommendedOrganizationTitle: {
    id: `${scope}.recommendedOrganizationTitle`,
    defaultMessage: 'Recommended organizations related to this project'
  },
  RecommendedUserOrganizationLabel: {
    id: `${scope}.RecommendedUserOrganizationLabel`,
    defaultMessage: 'Recommended user or organization related to this project'
  },
  initiativeTitleLabel: {
    id: `${scope}.initiativeTitleLabel`,
    defaultMessage: 'Initiative Title'
  },
  initiativeTitlePlaceholder: {
    id: `${scope}.initiativeTitlePlaceholder`,
    defaultMessage: 'Write your Initiative title here'
  },
  initiativeTypeLabel: {
    id: `${scope}.initiativeTypeLabel`,
    defaultMessage: 'Initiative Type'
  },
  initiativeTypePlaceholder: {
    id: `${scope}.initiativeTypePlaceholder`,
    defaultMessage: 'Select User Initiative Type'
  },
  initiativeSubTypeLabel: {
    id: `${scope}.initiativeSubTypeLabel`,
    defaultMessage: 'Initiative Sub Type'
  },
  initiativeSubTypePlaceholder: {
    id: `${scope}.initiativeSubTypePlaceholder`,
    defaultMessage: 'Select User Initiative Sub Type'
  },
  initiativeTeaserPlaceHolder: {
    id: `${scope}.initiativeTeaserPlaceHolder`,
    defaultMessage: `Craft a compelling Teaser that grabs readers' attention and summarizes your post.`
  },
  editorLabel: {
    id: `${scope}.editorLabel`,
    defaultMessage: 'Content'
  },
  editorPlaceholder: {
    id: `${scope}.editorPlaceholder`,
    defaultMessage: 'Write your content here'
  },
  keywordsLabel: {
    id: `${scope}.keywordsLabel`,
    defaultMessage: 'Keywords'
  },
  keywordsPlaceholder: {
    id: `${scope}.keywordsPlaceholder`,
    defaultMessage: 'Add some keywords related to your initiative'
  },
  privacyLabel: {
    id: `${scope}.privacyLabel`,
    defaultMessage: 'Privacy'
  },
  privacyPlaceholder: {
    id: `${scope}.privacyPlaceholder`,
    defaultMessage: 'Select your privacy options..'
  },
  visibilityLabel: {
    id: `${scope}.visibilityLabel`,
    defaultMessage: 'Visibility'
  },
  visibilityPlaceholder: {
    id: `${scope}.visibilityPlaceholder`,
    defaultMessage: 'Select your visibility options..'
  },
  administratorsLabel: {
    id: `${scope}.administratorsLabel`,
    defaultMessage: 'Administrators'
  },
  administratorsPlaceholder: {
    id: `${scope}.administratorsPlaceholder`,
    defaultMessage: 'Select users to add as administrators'
  },
  cancelButton: {
    id: `${scope}.cancelButton`,
    defaultMessage: 'Cancel'
  },
  saveAsDraftButton: {
    id: `${scope}.saveAsDraftButton`,
    defaultMessage: 'Save as Draft'
  },
  markAsDraftButton: {
    id: `${scope}.markAsDraftButton`,
    defaultMessage: 'Mark as Draft'
  },
  updateDraftButton: {
    id: `${scope}.updateDraftButton`,
    defaultMessage: 'Update Draft'
  },
  publishButton: {
    id: `${scope}.publishButton`,
    defaultMessage: 'Publish Initiative'
  },
  updateInitiativeButton: {
    id: `${scope}.updateInitiativeButton`,
    defaultMessage: 'Update Initiative'
  },
  draftSuccessMessage: {
    id: `${scope}.draftSuccessMessage`,
    defaultMessage: 'Initiative Draft created successfully!'
  },
  addSuccessMessage: {
    id: `${scope}.addSuccessMessage`,
    defaultMessage: 'Initiative added successfully!'
  },
  updateSuccessMessage: {
    id: `${scope}.updateSuccessMessage`,
    defaultMessage: 'Initiative updated successfully!'
  },
  loadingUsersMessage: {
    id: `${scope}.loadingUsersMessage`,
    defaultMessage: 'Loading users'
  },
  addedLabel: {
    id: `${scope}.addedLabel`,
    defaultMessage: 'Added'
  },
  recommendedUserOrganizationLabel: {
    id: `${scope}.recommendedUserOrganizationLabel`,
    defaultMessage: 'Recommended users or organizations related to this project'
  },
  LoadingUsers: {
    id: `${scope}.LoadingUsers`,
    defaultMessage: 'Loading users'
  },
  LoadingKeywords: {
    id: `${scope}.LoadingKeywords`,
    defaultMessage: 'Loading keywords'
  },
  industrySector: {
    id: `${scope}.industrySector`,
    defaultMessage: 'Industry Sector'
  },
  industrySectorPlaceholder: {
    id: `${scope}.industrySectorPlaceholder`,
    defaultMessage: 'Please enter industry sector'
  },
  visibleOwner: {
    id: `${scope}.visibleOwner`,
    defaultMessage: 'Visibile owner for this initiative'
  },
  visibleEnterPrize: {
    id: `${scope}.visibleEnterPrize`,
    defaultMessage: 'Visible enterprise for this initiative'
  },
  userAlreadyExistsInviteNetwork: {
    id: `${scope}.userAlreadyExistsInviteNetwork`,
    defaultMessage: 'This user has already been added to your invitee network.'
  },
  administratorAlreadyExistsInviteNetwork: {
    id: `${scope}.userAlreadyExistsInviteNetwork`,
    defaultMessage: 'This administrator is already added to the invitee list.'
  },
  organizationAlreadyExistsInviteNetwork: {
    id: `${scope}.organizationAlreadyExistsInviteNetwork`,
    defaultMessage: 'This organization has already been added to your invitee network.'
  },
  organizationInvitationSuccess: {
    id: `${scope}.organizationAlreadyExistsInviteNetwork`,
    defaultMessage: `Invitation send to organization's mail successfully`
  },
  userInvitationSuccess: {
    id: `${scope}.organizationAlreadyExistsInviteNetwork`,
    defaultMessage: `Invitation send to user's mail successfully`
  }
});
