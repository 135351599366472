import { defineMessages } from 'react-intl';

export const scope = 'app.component.layout.mianLayout';

export default defineMessages({
  logout: {
    id: `${scope}.logout`,
    defaultMessage: 'Log out'
  }
});
