import { createApi } from '@reduxjs/toolkit/query/react';
import { RtkBaseQuery } from '../../utils/request';
import { API_REQUEST_METHOD } from 'utils/constants/globalConstant';
import queryEndPoints from 'utils/queryEndPoints';
import queryTags from 'utils/constants/queryTags';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: RtkBaseQuery,
  tagTypes: [queryTags.getOrganizationInfo],
  endpoints: (builder) => ({
    changePassword: builder.mutation({
      query: (body) => ({
        url: queryEndPoints.changePassword,
        method: API_REQUEST_METHOD.POST,
        body: body
      })
    }),
    setPassword: builder.mutation({
      query: ({ body, params }) => ({
        url: queryEndPoints.setPassword(params),
        method: API_REQUEST_METHOD.POST,
        body: body
      })
    }),
    resetPassword: builder.mutation({
      query: ({ body, params }) => ({
        url: queryEndPoints.resetPassword(params),
        method: API_REQUEST_METHOD.POST,
        body: body
      })
    }),
    resetPasswordLinkExpiration: builder.query({
      query: (id) => ({
        url: queryEndPoints.resetPasswordLinkExpiration(id),
        method: API_REQUEST_METHOD.GET
      })
    }),
    setPasswordLinkExpiration: builder.query({
      query: (id) => ({
        url: queryEndPoints.setPasswordLinkExpiration(id),
        method: API_REQUEST_METHOD.GET
      })
    }),
    getOrganizationInfoForUserRegistration: builder.query({
      query: (organizationId) => queryEndPoints.getOrganizationInfoForUserRegistration(organizationId),
      providesTags: [queryTags.getOrganizationInfo],
      transformResponse: (response) => response?.data?.data
    }),
    userRegistrationForOrganization: builder.mutation({
      query: ({ body, organizationId }) => {
        return {
          url: queryEndPoints.userRegistrationForOrganization(organizationId),
          method: API_REQUEST_METHOD.POST,
          body
        };
      },
      transformResponse: (response) => response?.data?.data,
      invalidatesTags: [queryTags.getOrganizationInfo]
    }),
    login: builder.mutation({
      query: (req) => ({
        url: queryEndPoints.login,
        method: API_REQUEST_METHOD.POST,
        body: req
      })
    }),
    forgetPasswordEmail: builder.mutation({
      query: (req) => ({
        url: queryEndPoints.forgetPasswordEmail,
        method: API_REQUEST_METHOD.POST,
        body: req
      })
    })
  })
});

export const {
  useChangePasswordMutation,
  useSetPasswordMutation,
  useResetPasswordMutation,
  useResetPasswordLinkExpirationQuery,
  useSetPasswordLinkExpirationQuery,
  useGetOrganizationInfoForUserRegistrationQuery,
  useUserRegistrationForOrganizationMutation,
  useLoginMutation,
  useForgetPasswordEmailMutation
} = authApi;
