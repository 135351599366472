import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Box, CircularProgress, Grid, Stack, Tooltip, Typography } from '@mui/material';
// import { getCapitalizeFirst } from 'utils/getCapitalize';
import { colors } from 'utils/constants/colors';
import { getCapitalizeFirst } from 'utils/getCapitalize';

const AvatarNameWithButton = ({
  avatarUrl,
  id,
  name,
  label,
  buttonIcon,
  onClick = () => {},
  isOrganization,
  size = 30,
  iconColor = colors.error[450],
  handleTitleClick,
  toolTipTitle
}) => {
  return (
    <Grid item sx={{ display: 'flex', alignItems: 'center', gap: 0.75 }} xs={12}>
      <Tooltip title={toolTipTitle}>
        <Box sx={{ position: 'relative', width: 'fit-content' }}>
          <CircularProgress
            variant="determinate"
            value={label ? label : null}
            sx={{
              position: 'absolute',
              top: '0%',
              left: '0%',
              zIndex: 1,
              width: '1.9rem !important',
              height: '1.8rem !important'
            }}
            color="success"
            thickness={4}
          />
          <Avatar
            alt={name}
            src={avatarUrl}
            sx={{ width: size, height: size }}
            // eslint-disable-next-line react/no-children-prop
            children={getCapitalizeFirst(name)}
          />
        </Box>
      </Tooltip>
      <Stack>
        {name && (
          <Typography
            onClick={handleTitleClick}
            // eslint-disable-next-line no-extra-boolean-cast
            sx={{ ':hover': { textDecoration: !!handleTitleClick ? 'underline' : '' }, cursor: 'pointer' }}
            noWrap
            maxWidth={'200px'}
          >
            {name}
          </Typography>
        )}
        {label && (
          <Typography color={colors.coolGray[500]} fontSize={9} noWrap maxWidth={'100px'}>
            {`${label}% Match`}
          </Typography>
        )}
      </Stack>
      {buttonIcon &&
        cloneElement(buttonIcon, {
          style: { color: iconColor, width: 16, height: 16, cursor: 'pointer' },
          onClick: () => onClick(id, isOrganization)
        })}
    </Grid>
  );
};

export default AvatarNameWithButton;

AvatarNameWithButton.propTypes = {
  avatarUrl: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  buttonIcon: PropTypes.element,
  onClick: PropTypes.func,
  isOrganization: PropTypes.bool,
  size: PropTypes.number,
  iconColor: PropTypes.string,
  label: PropTypes.number,
  handleTitleClick: PropTypes.func,
  toolTipTitle: PropTypes.string
};
