import React from 'react';
import { useIntl } from 'react-intl';
import InsightBox from 'components/Accessiblity/InsightBox';
import messages from './messages';

const TrainingTab = () => {
  const intl = useIntl();
  return (
    <InsightBox
      title={intl.formatMessage(messages.ComingSoon)}
      description={intl.formatMessage(messages.ThanksForSharingInterestInTrainings)}
    />
  );
};
export default TrainingTab;
