import { defineMessages } from 'react-intl';

export const scope = 'app.store.reducers';

export default defineMessages({
  headerYou: {
    id: `${scope}.headerYou`,
    defaultMessage: `Click through to complete and customize your profile. Access license administration here if you have the privilege.`
  },
  headerFeedback: {
    id: `${scope}.headerFeedback`,
    defaultMessage: ` We value your opinion! Please share your feedback about Innofuse with us.`
  },
  HamburgerMenu: {
    id: `${scope}.HamburgerMenu`,
    defaultMessage: `Main menu: access the various matchmaking hubs.`
  },
  headerSearch: {
    id: `${scope}.headerSearch`,
    defaultMessage: `Innofuse search engine “Reactor”: searching in entire ecosystem`
  },
  headerNews: {
    id: `${scope}.headerNews`,
    defaultMessage: ` Check out which feature is on its way.`
  },
  licenseAdministration: {
    id: `${scope}.licenseAdministration`,
    defaultMessage: `If you are the administrator of
your enterprise or university
licenses, you will see this menu
item ‘License Administration’ when you click on you button.`
  },
  enterpriseImage: {
    id: `${scope}.enterpriseImage`,
    defaultMessage: `Edit your enterprise
or university image.`
  },
  enterpriseDetails: {
    id: `${scope}.enterpriseDetails`,
    defaultMessage: `Edit your enterprise or
university details. You can
also change the enterprise
or university email address
here, independent of the
person behind it.`
  },
  enterpriseKeywords: {
    id: `${scope}.enterpriseKeywords`,
    defaultMessage: `Edit your enterprise or
university keywords
here, these is important
to create matches to
you and others.`
  },
  enterpriseUsers: {
    id: `${scope}.enterpriseUsers`,
    defaultMessage: `Add and edit the
persons who are part of
your enterprise or
university license.`
  },
  profile: {
    id: `${scope}.profile`,
    defaultMessage: `Access your personal profile
page here.`
  },
  profileImage: {
    id: `${scope}.profileImage`,
    defaultMessage: `Edit your personal profile image
here.`
  },
  profileDetails: {
    id: `${scope}.profileDetails`,
    defaultMessage: `Edit your personal details here.
For some you can choose to keep
them for yourself.`
  },
  profileKeywords: {
    id: `${scope}.profileKeywords`,
    defaultMessage: `Add and edit your experience and
interests here. They are important to
create matches for you and the
others. If you want to keep an
expertise hidden from others, add it to
‘hidden expertise’.`
  },
  profileExtraFields: {
    id: `${scope}.profileExtraFields`,
    defaultMessage: `Add and edit project experience,
industries & academia fields, your
publications, your patents here. These
are important to create matching and
for others to understand who you are.`
  },
  chat: {
    id: `${scope}.chat`,
    defaultMessage: 'Open chat window'
  },
  chatUsers: {
    id: `${scope}.chatUsers`,
    defaultMessage: `Chat with contacts you have
connected with through the initiative or
networking hub.`
  },
  chatUserSearch: {
    id: `${scope}.chatUserSearch`,
    defaultMessage: `Search for names in your existing
chats. You cannot search the entire
Innofuse network here. If you want to start
a new chat, you need to navigate to a
profile and click the ‘Message’ button.`
  },
  chatMessage: {
    id: `${scope}.chatMessage`,
    defaultMessage: `Type in your message and press the
button to send it to the selected person.`
  },
  myInnofuse: {
    id: `${scope}.myInnofuse`,
    defaultMessage: `“My Initiatives” displays your
most recent initiatives.`
  },
  myInnofuseAllButton: {
    id: `${scope}.myInnofuseAllButton`,
    defaultMessage: `Click “All” to check all your
initiatives.`
  },
  myInnofuseAddInitiatives: {
    id: `${scope}.myInnofuseAddInitiatives`,
    defaultMessage: `Add / create an initiative by
navigating through the mask.`
  },
  mostMatchingInitiatives: {
    id: `${scope}.mostMatchingInitiatives`,
    defaultMessage: `Displays most matching
initiatives to you and your activities`
  },
  mostMatchingNetwork: {
    id: `${scope}.mostMatchingNetwork`,
    defaultMessage: `Display most matching network entities to you and your activities. `
  },
  watchedInitiatives: {
    id: `${scope}.watchedInitiatives`,
    defaultMessage: `Displays all initiatives which you are actively watching.`
  },
  reactorSearch: {
    id: `${scope}.reactorSearch`,
    defaultMessage: `Search the Innofuse
Reactor`
  },
  reactorTabs: {
    id: `${scope}.reactorTabs`,
    defaultMessage: `Navigate Reactor
search per
information types.`
  },
  reactorButton: {
    id: `${scope}.reactorButton`,
    defaultMessage: `Safe your Reactor
search result as an
initiative and remain
informed about news.`
  },
  reactorInitiatives: {
    id: `${scope}.reactorInitiatives`,
    defaultMessage: `Click to see more information. Note:
some information might be hidden. To
see full set of information you might
contact the owner by navigating to the menu .`
  },
  reactorInitiativesScore: {
    id: `${scope}.reactorInitiatives`,
    defaultMessage: 'Matching score'
  },
  networkCard: {
    id: `${scope}.networkCard`,
    defaultMessage: `Network member with matching
score and matching information.`
  },
  networkTabs: {
    id: `${scope}.networkTabs`,
    defaultMessage: `Specialized “Network only”
matchmaking.`
  },
  initiativesCard: {
    id: `${scope}.initiativesCard`,
    defaultMessage: `Resulting Initiatives with
Matchmaking score, sorted by best
match.`
  },
  initiativesAddButton: {
    id: `${scope}.initiativesAddButton`,
    defaultMessage: `Add / create a new initiative`
  },
  initiativesMenuIcon: {
    id: `${scope}.initiativesMenuIcon`,
    defaultMessage: `Edit initiative or
interact with Initiative`
  },
  initiativesTabs: {
    id: `${scope}.initiativesTabs`,
    defaultMessage: `Specialized “Initiative only”
matchmaking`
  },
  initiativesTypes: {
    id: `${scope}.initiativesTypes`,
    defaultMessage: `Select type of initiative.`
  },
  initiativesSubTypes: {
    id: `${scope}.initiativesSubTypes`,
    defaultMessage: `Select sub-type of initiative.`
  },
  initiativesState: {
    id: `${scope}.initiativesState`,
    defaultMessage: `State ownership in case of
enterprise ownership.`
  },
  initiativesPrivacy: {
    id: `${scope}.initiativesPrivacy`,
    defaultMessage: `Decide who can join the
initiative – privacy.`
  },
  initiativesVisibility: {
    id: `${scope}.initiativesVisibility`,
    defaultMessage: `Decide who can see the initiative
– visibility.`
  },
  initiativesVisibilityButton: {
    id: `${scope}.initiativesVisibilityButton`,
    defaultMessage: `Enable or disable visibility of the
information by clicking on the eye
symbol.`
  }
});
