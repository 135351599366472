import React, { cloneElement, useMemo } from 'react';
import { Stack, styled, Typography, useTheme } from '@mui/material';
import { IconBrandAsana, IconTie } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import { CustomChip } from 'components/@common';
import { colors } from 'utils/constants/colors';
import { INITIATIVE_TYPE, ROLE_LABEL } from 'utils/constants/initiatives';
import { getRoleLabelForInitiativeBox, returnIconBasedOnInitiativesStates } from 'utils/utils';

export const MatchingPercentage = styled(Typography)(({ theme, fontSize }) => ({
  color: theme.palette.success.main,
  background: theme.palette.success.lighter,
  fontSize: fontSize ? fontSize : '14px !important',
  padding: '4px 10px',
  borderRadius: '3px',
  boxShadow: 'none',
  [theme.breakpoints.down('sm')]: {
    fontSize: fontSize ? fontSize : '12px !important',
    padding: '4px 6px'
  }
}));

const SubHeaderInnofuseBox = ({ initiativeInfo = {}, userId = '', initiativeType = '' }) => {
  const { matchingScore = 0, initiativeState = '' } = initiativeInfo;
  const { palette } = useTheme();
  const roleLabel = useMemo(() => getRoleLabelForInitiativeBox(initiativeInfo, userId), [initiativeInfo, userId]);
  return (
    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} gap={1.25}>
      <Stack direction={'row'} alignItems={'center'} gap={1}>
        {cloneElement(returnIconBasedOnInitiativesStates(initiativeState), { style: { color: palette.primary.light } })}
        {!!roleLabel && (
          <CustomChip
            chipSx={{ mt: 0, color: colors.dark[550], p: '0.1rem' }}
            label={roleLabel}
            rightIcon={
              roleLabel === ROLE_LABEL.ADMINISTRATOR ? (
                <IconTie color={colors.dark[550]} />
              ) : roleLabel === ROLE_LABEL.MEMBER ? (
                <IconBrandAsana color={colors.dark[550]} />
              ) : null
            }
            variant={'filled'}
          />
        )}
      </Stack>
      {matchingScore !== null && initiativeType !== INITIATIVE_TYPE.SELF && initiativeType !== INITIATIVE_TYPE.WATCHED && (
        <MatchingPercentage className="reactor-initiatives-score">{`${matchingScore?.toFixed(2)}% Match`}</MatchingPercentage>
      )}
    </Stack>
  );
};

SubHeaderInnofuseBox.propTypes = {
  initiativeInfo: PropTypes.object,
  userId: PropTypes.string,
  initiativeType: PropTypes.string
};

export default SubHeaderInnofuseBox;
