import { fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import globalConstant from './constants/globalConstant';

export const BASE_URL = process.env.REACT_APP_BASE_API;

export const makeHttpRequest = async (route, method, data = null) => {
  const authToken = JSON.parse(localStorage.getItem(globalConstant.JWT_TOKEN));
  const tokenString = `Bearer ${authToken}`;

  const options = {
    method: method, // GET, POST, PUT, DELETE, etc.
    headers: {
      Authorization: tokenString ?? '',
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  if (data) {
    options.body = JSON.stringify(data);
  }
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await fetch(`${BASE_URL}${route}`, options);
    if (!response.ok) {
      throw new Error('Something went wrong !');
    }
    const result = await response.json();
    return result;
  } catch (err) {
    throw err;
  }
};

export const RtkBaseQuery = retry(
  fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      const token = JSON.parse(localStorage.getItem(globalConstant.JWT_TOKEN));
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  { maxRetries: globalConstant.DEFAULT_MAX_RETRIES_CONTROLLER_LEVEL }
);

export const customBackOff = async () => {
  await new Promise((resolve) => {
    setTimeout(resolve, 2000);
  });
};
