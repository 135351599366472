import React, { useState } from 'react';
import Dialog from 'components/@common/Dialog';
import FormTextField from 'components/Forms/FormTextField';
import { StyledLabelComponent } from 'components/Forms/LabelWithAstrik';
import { useFormik } from 'formik';
import { toast } from 'react-hot-toast';
import PropTypes from 'prop-types';
import { IconButton, Grid } from '@mui/material';
import { useIntl } from 'react-intl';
import { changePasswordSchema } from './schema';
import messages from './messages';
import { useGetUserInfoQuery } from 'store/rtk-query/userApi';
import { useChangePasswordMutation } from 'store/rtk-query/authApi';
import { generateSHA512 } from 'utils/utils';

export default function ChangePasswordDialog({ setOpenPasswordChange, openPasswordChange }) {
  const intl = useIntl();
  const { data: user } = useGetUserInfoQuery();
  const [showPassword, setShowPassword] = useState(false);
  const [changePassword, resultObj] = useChangePasswordMutation();
  const handleClosePasswordChange = () => {
    setOpenPasswordChange(false);
    passwordChangeReset();
  };

  const {
    values: passwordChangeFormValues,
    handleChange: passwordChangeFormHandleChange,
    handleBlur: passwordChangeFormHandleBlur,
    handleSubmit: passwordChangeFormHandleSubmit,
    errors: passwordChangeFormErrors,
    touched: passwordChangeFormTouched,
    resetForm: passwordChangeReset
  } = useFormik({
    validationSchema: changePasswordSchema,
    initialValues: {
      email: user?.email || '',
      password: '',
      newPassword: '',
      confirmPassword: ''
    },
    onSubmit: async (values) => {
      const clonedValues = structuredClone(values);
      clonedValues.password = generateSHA512(values.password);
      clonedValues.newPassword = generateSHA512(values.newPassword);
      clonedValues.confirmPassword = generateSHA512(values.confirmPassword);
      const result = await changePassword(clonedValues);
      if (result?.data?.data) {
        setOpenPasswordChange(false);
        toast.success('Password changed successfully !');
        passwordChangeReset();
      } else {
        toast.error(result.error.data.data.error || 'Operation failed. Please try again.');
      }
    }
  });
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <Dialog
      isOpen={openPasswordChange}
      cancelSubmitHandle={handleClosePasswordChange}
      handleClose={handleClosePasswordChange}
      okSubmitHandle={passwordChangeFormHandleSubmit}
      okBtnText={intl.formatMessage(messages.passwordChangeButtonText)}
      title={intl.formatMessage(messages.passwordChangeTitle)}
      loading={resultObj.isLoading}
    >
      <form onSubmit={passwordChangeFormHandleSubmit}>
        <Grid container spacing={3}>
          <FormTextField
            type="password"
            endIcon={
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
                color="secondary"
                size="small"
              ></IconButton>
            }
            key={0}
            isFullWidth={true}
            name="password"
            label={<StyledLabelComponent label={intl.formatMessage(messages.passwordChangeOldPassLabel)} />}
            placeholder={intl.formatMessage(messages.passwordChangeOldPassPlaceHolder)}
            value={passwordChangeFormValues.password}
            onChange={passwordChangeFormHandleChange('password')}
            errorMsg={passwordChangeFormTouched.password ? passwordChangeFormErrors.password : ''}
            onBlur={passwordChangeFormHandleBlur('password')}
          />
          <FormTextField
            type="password"
            endIcon={
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
                color="secondary"
                size="small"
              ></IconButton>
            }
            key={1}
            isFullWidth={true}
            name="newPassword"
            label={<StyledLabelComponent label={intl.formatMessage(messages.passwordChangeNewPassLabel)} />}
            placeholder={intl.formatMessage(messages.passwordChangeNewPassPlaceHolder)}
            value={passwordChangeFormValues.newPassword}
            onChange={passwordChangeFormHandleChange('newPassword')}
            errorMsg={passwordChangeFormTouched.newPassword ? passwordChangeFormErrors.newPassword : ''}
            onBlur={passwordChangeFormHandleBlur('newPassword')}
          />
          <FormTextField
            type="password"
            endIcon={
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
                color="secondary"
                size="small"
              ></IconButton>
            }
            key={2}
            isFullWidth={true}
            name="confirmPassword"
            label={<StyledLabelComponent label={intl.formatMessage(messages.passwordChangeConfirmPassLabel)} />}
            placeholder={intl.formatMessage(messages.passwordChangeConfirmPassPlaceHolder)}
            value={passwordChangeFormValues.confirmPassword}
            onChange={passwordChangeFormHandleChange('confirmPassword')}
            errorMsg={passwordChangeFormTouched.confirmPassword ? passwordChangeFormErrors.confirmPassword : ''}
            onBlur={passwordChangeFormHandleBlur('confirmPassword')}
          />
        </Grid>
      </form>
    </Dialog>
  );
}

ChangePasswordDialog.propTypes = {
  setOpenPasswordChange: PropTypes.func,
  openPasswordChange: PropTypes.bool
};
