import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
  initiativesKeywordState: {
    relatedToMe: true,
    relatedToInitiativeId: '',
    filterKeywordState: [],
    offset: false
  },
  networkingKeywordState: {
    relatedToMe: true,
    relatedToInitiativeId: '',
    filterKeywordState: [],
    offset: false
  }
};

// ==============================|| SLICE - MENU ||============================== //

export const searchKeywordReducer = createSlice({
  name: 'searchKeywordReducer',
  initialState,
  reducers: {
    setInitiativesKeywordState(state, action) {
      state.initiativesKeywordState = action.payload;
    },
    setNetworkingKeywordState(state, action) {
      state.networkingKeywordState = action.payload;
    }
  }
});

export const { setInitiativesKeywordState, setNetworkingKeywordState } = searchKeywordReducer.actions;
