import { Skeleton, Stack } from '@mui/material';
import React from 'react';

const NotificationSkeleton = () => {
  return (
    <Stack gap={2}>
      {[1, 2, 3].map((item) => (
        <Stack key={item} direction={'row'} gap={1} padding="10px 20px">
          <Skeleton sx={{ width: 50, height: 50 }} variant="circular" />
          <Stack width={'100%'} gap={1}>
            <Skeleton variant="rectangular" height={20} />
            <Skeleton variant="rectangular" height={50} />
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};

export default NotificationSkeleton;
