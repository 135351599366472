import React from 'react';
import { Box, Button, Divider, Grid, Stack, TextField, Typography, useMediaQuery } from '@mui/material';
import { IconShare, IconDotsVertical, IconUserSquareRounded, IconBuildingSkyscraper, IconLink } from '@tabler/icons-react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import messages from './messages';
import InitiativeDetailLabel, { StaticLabel } from './InitiativeDetailLabel';
import SubHeaderInnofuseBox from './SubHeaderInnofuseBox';
import useElementWidth from 'hooks/useElementWidth';
import { IconButton, ActionComponent, MenuIconComponent, OneLinerTypographyWithTooltip, Dialog, MainCard } from 'components/@common';
import { createUserName } from 'utils/utils';
import { colors } from 'utils/constants/colors';
import useInitiativesInfoBox from 'hooks/useInitiativesInfoBox';
import { INITIATIVE_TYPE } from 'utils/constants/initiatives';

export const StyledRenderHtmlBox = styled(Box)(() => ({
  display: '-webkit-box',
  WebkitLineClamp: 1,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
  wordBreak: 'break-word',
  color: colors.dark[550],
  '& p': {
    margin: '0px'
  }
}));

const InitiativesInfoBox = ({
  initiativeInfo,
  userId,
  showLink = false,
  handleLinkInitiative,
  initiativeType = '',
  refetchInitiatives,
  className = ''
}) => {
  const { elementWidth: mainCardWidth, elementRef: mainCardRef } = useElementWidth();
  const {
    uuid: initiativeId,
    title = 'Anonymous',
    privacy = 'Anonymous',
    subType = 'Anonymous',
    type,
    teaser = 'Anonymous',
    industrySector,
    initiativeState,
    userInfo,
    organization = null,
    userHasLiked,
    userHasDisliked,
    likeCount,
    dislikeCount,
    commentCount,
    isLinked = false,
    isInWatchedList = false,
    keywords = []
  } = initiativeInfo;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const {
    options,
    handleClose,
    intl,
    palette,
    handleCopyURL,
    handleSubmit,
    handleContactAdminsTextSubmit,
    handleActionButton,
    handleNavigateToInitiative,
    handleClick,
    handleCloseArchiveInitiativeAlertDialog,
    isInitiativeUpdateLoading,
    isUserMessageToOwnerLoading,
    archiveInitiativeDialogIsOpen,
    contactAdminsDialogIsOpen,
    shouldReactionShow,
    open,
    archivedStates,
    draftStates,
    anchorEl,
    setContactAdminMessage,
    setContactAdminsDialogIsOpen,
    contactAdminMessage
  } = useInitiativesInfoBox(initiativeInfo, userId, initiativeId, initiativeType, refetchInitiatives, isInWatchedList, initiativeState);

  const isOrganizationExist = Boolean(organization?.name);

  const ownerName = isOrganizationExist ? organization?.name || 'Anonymous' : createUserName(userInfo);

  const currentReaction = {
    userHasLiked,
    userHasDisliked
  };

  return (
    <>
      <MainCard
        title={
          <OneLinerTypographyWithTooltip
            text={title || 'Anonymous'}
            tooltipText={title || 'Anonymous'}
            sxPropsBox={{
              maxWidth: mainCardWidth * 0.75
            }}
          />
        }
        subheader={<SubHeaderInnofuseBox initiativeInfo={initiativeInfo} userId={userId} initiativeType={initiativeType} />}
        sx={{ borderRadius: '10px', boxShadow: '0px 4px 15px 0px rgba(46, 108, 164, 0.09)' }}
        onClickCardHeader={() => handleNavigateToInitiative(showLink)}
        ref={mainCardRef}
        className={className}
      >
        {isLinked ? (
          <Button
            variant="contained"
            sx={{
              position: 'absolute',
              top: isMobile ? 15 : 20,
              right: isMobile ? 10 : 20,
              boxShadow: 'none',
              padding: 0
            }}
          >
            Linked
          </Button>
        ) : showLink ? (
          <IconButton
            sx={{
              position: 'absolute',
              top: isMobile ? 0 : 10,
              right: isMobile ? 0 : 10,
              bgcolor: 'white',
              background: 'transparent',
              boxShadow: 'none',
              color: 'primary.main',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer'
            }}
            onClick={() => handleLinkInitiative(initiativeId)}
          >
            <IconLink size={30} />
          </IconButton>
        ) : (
          <MenuIconComponent
            options={options}
            anchorEl={anchorEl}
            open={open}
            handleClick={handleClick}
            handleClose={handleClose}
            id={initiativeId}
            renderIcon={<IconDotsVertical />}
            width={50}
            height={50}
            sx={{
              position: 'absolute',
              top: isMobile ? '-4px' : 0,
              right: isMobile ? '-15px' : 0,
              bgcolor: 'white',
              background: 'transparent',
              boxShadow: 'none',
              color: 'primary.main',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer'
            }}
            iconClassName="initiatives-icon"
          />
        )}
        <Box onClick={() => handleNavigateToInitiative(showLink)} sx={{ cursor: 'pointer' }}>
          <Stack direction="row">
            <StaticLabel mr={0.5} variant="h6">
              {intl.formatMessage(messages.Owner)}:
            </StaticLabel>
            <Typography color={colors.dark[550]} sx={{ ml: 0.5 }} fontSize={{ xs: '0.75rem', msm: '0.9rem' }}>
              {ownerName}
            </Typography>
            <Box display={'flex'} pl={0.5}>
              {isOrganizationExist ? (
                <IconBuildingSkyscraper stroke={1.5} color={palette.primary.main} size={18} />
              ) : (
                <IconUserSquareRounded stroke={1.5} color={palette.primary.main} size={18} />
              )}
            </Box>
          </Stack>
          <InitiativeDetailLabel label1={intl.formatMessage(messages.Privacy)} label2={privacy} />
          <InitiativeDetailLabel label1={intl.formatMessage(messages.InitiativeType)} label2={subType} type={type} />
          {industrySector && <InitiativeDetailLabel label1={intl.formatMessage(messages.IndustrySector)} label2={industrySector} />}
          {initiativeType !== INITIATIVE_TYPE.SELF && initiativeType !== INITIATIVE_TYPE.WATCHED && (
            <Stack direction={'row'} sx={{ flexWrap: 'wrap' }}>
              <StaticLabel sx={{ mr: '8px' }}>{intl.formatMessage(messages.MatchingTopics)}</StaticLabel>
              {keywords?.length > 0 && (
                <OneLinerTypographyWithTooltip
                  text={keywords?.join(', ')}
                  tooltipText={keywords?.join(', ')}
                  lineCount={2}
                  sxPropsBox={{ color: colors.dark[550], fontSize: { xs: '0.75rem', msm: '0.9rem' } }}
                />
              )}
            </Stack>
          )}
          <StaticLabel>{intl.formatMessage(messages.Teaser)}</StaticLabel>
          <StyledRenderHtmlBox dangerouslySetInnerHTML={{ __html: teaser || 'Anonymous' }} />
        </Box>
        <Divider />
        {shouldReactionShow && (
          <Grid container sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: '10px' }}>
            <Grid item sx={{ display: 'flex', gap: { xs: 1, md: 3 } }}>
              <ActionComponent
                likeCount={likeCount}
                dislikeCount={dislikeCount}
                commentCount={commentCount}
                currentReaction={currentReaction}
                isLiked={userHasLiked}
                isDisLiked={userHasDisliked}
                handleActionButton={handleActionButton}
                handleCommentActionButton={() => handleNavigateToInitiative(showLink)}
                disableComment={showLink}
              />
            </Grid>
            <Grid item>
              <IconButton onClick={handleCopyURL}>
                <IconShare color={colors.info[450]} />
              </IconButton>
            </Grid>
          </Grid>
        )}
      </MainCard>
      {archiveInitiativeDialogIsOpen && (
        <Dialog
          isOpen={archiveInitiativeDialogIsOpen}
          cancelSubmitHandle={handleCloseArchiveInitiativeAlertDialog}
          handleClose={handleCloseArchiveInitiativeAlertDialog}
          content={`Are you sure you want to ${archivedStates || draftStates ? 'publish' : 'archive'} this initiative?`}
          okSubmitHandle={handleSubmit}
          okBtnText={archivedStates || draftStates ? 'Publish' : 'Archive'}
          title={archivedStates || draftStates ? 'Publish initiatives' : 'Archive initiative'}
          loading={isInitiativeUpdateLoading}
        />
      )}
      <Dialog
        isOpen={contactAdminsDialogIsOpen}
        cancelSubmitHandle={() => {
          setContactAdminMessage('');
          return setContactAdminsDialogIsOpen(false);
        }}
        handleClose={() => {
          setContactAdminMessage('');
          return setContactAdminsDialogIsOpen(false);
        }}
        content={
          <Grid container width={(theme) => theme.spacing(90)}>
            <TextField
              focused
              fullWidth
              label={intl.formatMessage(messages.MessageLabelForOwner)}
              minRows={4}
              placeholder={intl.formatMessage(messages.WriteMessageForAdmin)}
              onChange={({ target: { value } }) => setContactAdminMessage(value)}
              value={contactAdminMessage}
              multiline
              sx={{
                '& .MuiOutlinedInput-notchedOutline legend': {
                  fontSize: '0.75rem'
                },
                '& .MuiInputLabel-root': {
                  color: 'black !important',
                  fontSize: '16px'
                }
              }}
            />
          </Grid>
        }
        isOkBtnDisable={!contactAdminMessage}
        okSubmitHandle={handleContactAdminsTextSubmit}
        okBtnText={'Send'}
        cancelBtnText="Cancel"
        title={'Contact Admin'}
        loading={isUserMessageToOwnerLoading}
      />
    </>
  );
};

InitiativesInfoBox.propTypes = {
  userId: PropTypes.string,
  initiativeInfo: PropTypes.object,
  showLink: PropTypes.bool,
  handleLinkInitiative: PropTypes.func,
  initiativeType: PropTypes.string,
  refetchInitiatives: PropTypes.func,
  className: PropTypes.string
};
export default InitiativesInfoBox;
