import { createApi, defaultSerializeQueryArgs } from '@reduxjs/toolkit/query/react';
import toast from 'react-hot-toast';
import { RtkBaseQuery } from '../../utils/request';
import { initiativesApi } from './initiativesApi';
import queryTags from 'utils/constants/queryTags';
import { API_REQUEST_METHOD } from 'utils/constants/globalConstant';
import queryEndPoints from 'utils/queryEndPoints';
import { getRandomInt } from 'utils/getRandomInt';
import { reactionType } from 'utils/constants/initiatives';

export const newsApi = createApi({
  reducerPath: 'newsApi',
  baseQuery: RtkBaseQuery,
  tagTypes: [queryTags.getAllNews, queryTags.getNewsById],
  endpoints: (builder) => ({
    getAllNews: builder.query({
      query: (initiativeId) => queryEndPoints.getAllNews(initiativeId),
      transformResponse: (response) => {
        return response?.data?.data?.resultList?.map((newsItem) => {
          // parsing because the backend sends it as a string
          const parsedReaction = JSON.parse(newsItem.reactions);
          return {
            ...newsItem,
            reactions: parsedReaction
          };
        });
      },
      providesTags: [queryTags.getAllNews]
    }),
    getNewsById: builder.query({
      query: ({ initiativeId, newsId }) => queryEndPoints.getNewsById(initiativeId, newsId),
      providesTags: [queryTags.getNewsById],
      transformResponse: (response) => {
        const parsedReaction = JSON.parse(response?.data?.data?.reactions);
        return { ...response?.data?.data, reactions: parsedReaction };
      }
    }),
    AddNews: builder.mutation({
      query: ({ initiativeId, body }) => {
        return {
          url: queryEndPoints.AddNews(initiativeId),
          method: API_REQUEST_METHOD.POST,
          body
        };
      },
      transformResponse: (response) => response?.data,
      invalidatesTags: [queryTags.getAllNews],
      async onQueryStarted(_, { dispatch }) {
        dispatch(initiativesApi.util.invalidateTags([queryTags.initiativesTabsCount]));
      }
    }),
    updateNews: builder.mutation({
      query: ({ initiativeId, newsId, body }) => {
        return {
          url: queryEndPoints.updateNews({ initiativeId, newsId }),
          method: API_REQUEST_METHOD.PATCH,
          body
        };
      },
      invalidatesTags: [queryTags.getAllNews, queryTags.getNewsById],
      async onQueryStarted(_, { dispatch }) {
        dispatch(initiativesApi.util.invalidateTags([queryTags.initiativesTabsCount]));
      }
    }),
    deleteNews: builder.mutation({
      query: ({ initiativeId, newsId }) => {
        return {
          url: queryEndPoints.deleteNews({ initiativeId, newsId }),
          method: API_REQUEST_METHOD.DELETE
        };
      },
      invalidatesTags: [queryTags.getAllNews, queryTags.getNewsById]
    }),
    addNewsReaction: builder.mutation({
      query: ({ body, initiativeId, newsId }) => {
        return {
          url: queryEndPoints.addNewsReaction(initiativeId, newsId),
          method: API_REQUEST_METHOD.POST,
          body
        };
      },
      async onQueryStarted(
        {
          initiativeId,
          newsId,
          reactionId,
          body: {
            type,
            user: { uuid }
          }
        },
        { dispatch, queryFulfilled }
      ) {
        const randomReactionId = getRandomInt();
        // Step 1 - Optimistic updates on reaction in all contributions
        const updateReactionOptimistically = dispatch(
          newsApi.util.updateQueryData('getAllNews', initiativeId, (prev) => {
            const currentNews = prev;
            // Find the item in resultList based on some condition, for example, newsId  and id
            const newsIndex = currentNews?.findIndex((item) => item.id === +newsId);
            if (newsIndex !== -1) {
              const reactionsArray = currentNews[newsIndex]?.reactions ?? [];
              // Find the index of the existing reaction within the found initiative
              const reactionIndex = reactionsArray.findIndex((reaction) => reaction.id === reactionId);
              // If the reaction is found, replace it if type is already same remove it if type is different then add it
              if (reactionIndex !== -1) {
                const currentReaction = reactionsArray[reactionIndex];
                reactionsArray.splice(reactionIndex, 1, {
                  ...currentReaction,
                  type: currentReaction.type === type ? '' : type
                });
              } else {
                // If the reaction is not found, unshift the new reaction
                reactionsArray.unshift({
                  id: randomReactionId,
                  type: type,
                  user_id: uuid
                });
              }
            }
          })
        );

        // Step 2 - Update reaction in a single contributions
        const updateReactionOptimisticallyInSingleInitiative = dispatch(
          newsApi.util.updateQueryData('getNewsById', { initiativeId, newsId }, (draft) => {
            // Find the index of the existing reaction within the contributions details
            const reactionIndex = draft.reactions.findIndex((reaction) => reaction.id === reactionId);
            // If the reaction is found, replace it if type is already same remove it if type is different then add it
            if (reactionIndex !== -1) {
              const currentReaction = draft.reactions[reactionIndex];
              draft.reactions.splice(reactionIndex, 1, {
                ...currentReaction,
                type: currentReaction.type === type ? '' : type
              });
            } else {
              // If the reaction is not found, unshift the new reaction
              draft.reactions.unshift({
                id: randomReactionId,
                type: type,
                user_id: uuid
              });
            }
          })
        );

        // Step-3 update initiative activity count when reaction type is like
        if (type === reactionType.LIKE) {
          dispatch(
            initiativesApi.util.updateQueryData('initiativesTabsCount', initiativeId, (prev) => {
              prev.activities += 1;
            })
          );
        }

        try {
          await queryFulfilled;
        } catch (error) {
          toast.error(error?.message || 'Failed to add contributions reactions !');
          updateReactionOptimistically.undo();
          updateReactionOptimisticallyInSingleInitiative.undo();
        }
      }
    }),
    getAllNewsComments: builder.query({
      query: ({ initiativeId, newsId, offset, limit }) => queryEndPoints.getAllNewsComments(initiativeId, newsId, offset, limit),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const { initiativeId, newsId } = queryArgs;
        return defaultSerializeQueryArgs({
          endpointName,
          queryArgs: { initiativeId, newsId }
        });
      },
      merge: (currentCache, newItems, { arg }) => {
        const { offset } = arg;
        if (offset == 0) {
          currentCache.resultList = newItems.resultList;
          currentCache.totalCount = newItems.totalCount;
          return;
        }
        currentCache.resultList.push(...newItems.resultList);
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        // checking if query arguments has changed
        const checkRefetchCondition =
          currentArg.limit !== previousArg?.limit ||
          currentArg.offset !== previousArg?.offset ||
          currentArg?.initiativeId !== previousArg?.initiativeId ||
          currentArg?.newsId !== previousArg?.newsId;
        return checkRefetchCondition;
      },
      transformResponse: (response) => response?.data?.data,
      transformErrorResponse: (response) => response?.data,
      providesTags: [queryTags.getAllNewsComments]
    }),
    addNewsComment: builder.mutation({
      query: ({ body, initiativeId, newsId }) => {
        return {
          url: queryEndPoints.addNewsComment(initiativeId, newsId),
          method: API_REQUEST_METHOD.POST,
          body
        };
      },
      transformResponse: (response) => response?.data?.data,
      async onQueryStarted({ initiativeId, newsId }, { dispatch, queryFulfilled }) {
        try {
          const { data: newAddedComment } = await queryFulfilled;
          dispatch(
            newsApi.util.updateQueryData('getAllNewsComments', { initiativeId, newsId }, (prev) => {
              const currentAllComments = prev.resultList;
              currentAllComments.unshift(newAddedComment);
              prev.totalCount += 1;
            })
          );
          dispatch(
            newsApi.util.updateQueryData('getNewsById', { initiativeId, newsId }, (prev) => {
              prev.comment_count += 1;
            })
          );
          dispatch(
            newsApi.util.updateQueryData('getAllNews', initiativeId, (prev) => {
              const currentNewsArray = prev;
              const newsObj = currentNewsArray?.find((item) => item.id === +newsId);
              newsObj.comment_count += 1;
            })
          );
        } catch (error) {
          toast(error?.message || 'Failed to add comment !');
        }
      }
    }),
    updateNewsComment: builder.mutation({
      query: ({ body, commentId, newsId }) => {
        return {
          url: queryEndPoints.updateNewsComment({ commentId, newsId }),
          method: API_REQUEST_METHOD.PATCH,
          body
        };
      },
      transformResponse: (response) => response?.data?.data,
      invalidatesTags: [queryTags.getAllNewsComments]
    }),
    deleteNewsComment: builder.mutation({
      query: ({ commentId, newsId }) => {
        return {
          url: queryEndPoints.deleteNewsComment({ commentId, newsId }),
          method: API_REQUEST_METHOD.DELETE
        };
      },
      async onQueryStarted({ initiativeId, commentId, newsId }, { dispatch, queryFulfilled }) {
        const deleteNewsComment = dispatch(
          newsApi.util.updateQueryData(
            'getAllNewsComments',
            { initiativeId, newsId },
            (draft) => {
              draft.resultList = draft.resultList.filter((comment) => comment.id !== commentId);
              draft.totalCount -= 1;
            },
            true
          )
        );
        const decreaseCommentCountFormSingleNews = dispatch(
          newsApi.util.updateQueryData('getNewsById', { initiativeId, newsId }, (prev) => {
            prev.comment_count -= 1;
          })
        );
        const decreaseCommentCountFormAllNews = dispatch(
          newsApi.util.updateQueryData('getAllNews', initiativeId, (prev) => {
            const currentNewsArray = prev;
            const newsObj = currentNewsArray?.find((item) => item.id === +newsId);
            newsObj.comment_count -= 1;
          })
        );
        try {
          await queryFulfilled;
        } catch (error) {
          toast(error?.message || 'Failed to delete comment from initiative !');
          deleteNewsComment.undo();
          decreaseCommentCountFormSingleNews.undo();
          decreaseCommentCountFormAllNews.undo();
        }
      },
      transformResponse: (response) => response?.data?.data
      // invalidatesTags: [queryTags.getAllNewsComments]
    })
  })
});

export const {
  useGetAllNewsQuery,
  useGetNewsByIdQuery,
  useAddNewsMutation,
  useDeleteNewsMutation,
  useUpdateNewsMutation,
  useAddNewsReactionMutation,
  useAddNewsCommentMutation,
  useGetAllNewsCommentsQuery,
  useDeleteNewsCommentMutation,
  useUpdateNewsCommentMutation
} = newsApi;
