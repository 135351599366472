import { Breadcrumbs, Link } from '@mui/material';
import { IconChevronRight } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import { colors } from 'utils/constants/colors';

export const CustomBreadCrumbs = ({ items }) => {
  return (
    <Breadcrumbs aria-label="breadcrumb" separator={<IconChevronRight fontSize="small" color={colors.muted[950]} />}>
      {items.map((item, index) => (
        <Link fontSize={'1.1rem'} fontWeight={400} key={index} underline="hover" color="dark.labelTextColor" href={item.href}>
          {item.title}
        </Link>
      ))}
    </Breadcrumbs>
  );
};

CustomBreadCrumbs.propTypes = {
  items: PropTypes.array
};
