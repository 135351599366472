import { createApi } from '@reduxjs/toolkit/query/react';
import toast from 'react-hot-toast';
import { API_REQUEST_METHOD } from 'utils/constants/globalConstant';
import queryTags from 'utils/constants/queryTags';
import queryEndPoints from 'utils/queryEndPoints';
import { RtkBaseQuery } from 'utils/request';

export const keywordsApi = createApi({
  reducerPath: 'keywordsApi',
  baseQuery: RtkBaseQuery,
  tagTypes: [queryTags.keywords, queryTags.usersKeywords, queryTags.organizationKeywords, queryTags.recommendedKeywords],
  endpoints: (builder) => ({
    getAllKeywords: builder.query({
      query: ({ offset, limit, search }) => queryEndPoints.getAllKeywords({ offset, limit, search }),
      transformResponse: (response) => response?.data?.data,
      serializeQueryArgs: ({ endpointName }) => endpointName, // caching key override here
      merge: (currentCache, newItems, { arg }) => {
        const { offset } = arg;
        if (offset == 0) {
          currentCache.resultList = newItems.resultList;
          currentCache.totalCount = newItems.totalCount;
          return;
        }
        currentCache.resultList.push(...newItems.resultList);
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        // checking if query arguments has changed
        const checkRefetchCondition =
          currentArg.limit !== previousArg?.limit || currentArg.offset !== previousArg?.offset || currentArg.search !== previousArg?.search;
        return checkRefetchCondition;
      },
      providesTags: [queryTags.keywords],
      keepUnusedDataFor: 5 * 60 // 5 minutes cache time
    }),
    getRecommendedKeywords: builder.query({
      query: (userId) => queryEndPoints.getKeywordRecommended(userId),
      transformResponse: (response) => response?.data?.data,
      providesTags: [queryTags.recommendedKeywords]
    }),
    /** Users Keywords */
    getAllKeywordsByUserId: builder.query({
      query: (uuid) => queryEndPoints.getUserKeywordsByUserId(uuid),
      transformResponse: (response) => {
        const keywords = response?.data?.data.resultList
          ?.map((x) => !x.hidden && !x.interest && { id: x.id, title: x.keywords.title })
          .filter(Boolean);
        const hidden = response?.data?.data.resultList?.map((x) => x.hidden && { id: x.id, title: x.keywords.title }).filter(Boolean);
        const interest = response?.data?.data.resultList?.map((x) => x.interest && { id: x.id, title: x.keywords.title }).filter(Boolean);
        return { keywords, hidden, interest };
      },
      keepUnusedDataFor: 5 * 60, // 5 minutes cache time
      providesTags: [queryTags.usersKeywords]
    }),
    addUsersKeyword: builder.mutation({
      query: ({ userId, body }) => ({
        url: queryEndPoints.addKeywordsByUserIdV1(userId),
        method: API_REQUEST_METHOD.POST,
        body
      }),
      async onQueryStarted({ userId }, { dispatch, queryFulfilled }) {
        try {
          const { data: createdExpertise } = await queryFulfilled;
          const newExpertise = createdExpertise?.data?.data;
          const data = Object.fromEntries(Object.entries(newExpertise).filter(([a, b]) => a !== 'id' && b));
          const keys = Object.keys(data);
          const key = keys.at(-1);
          dispatch(
            keywordsApi.util.updateQueryData(
              'getAllKeywordsByUserId',
              userId,
              (draft) => {
                draft[key].push({ id: newExpertise.id, title: newExpertise.keywords.title });
              },
              true
            )
          );
          // add expertise to recommended keywords as well
          dispatch(
            keywordsApi.util.updateQueryData(
              'getRecommendedKeywords',
              userId,
              (draft) => {
                draft.push(newExpertise);
              },
              true
            )
          );
        } catch ({ error }) {
          toast.error(error?.data?.data?.error || 'Failed to add expertise !');
        }
      }
    }),
    deleteUsersKeyword: builder.mutation({
      query: ({ userId, expertiseId }) => ({
        url: queryEndPoints.deleteKeywordByUserIdAndKeywordId(userId, expertiseId),
        method: API_REQUEST_METHOD.DELETE
      }),
      async onQueryStarted({ userId, expertiseId }, { dispatch, queryFulfilled }) {
        // optimistic updates in RTK cache
        const deletePatchResult = dispatch(
          keywordsApi.util.updateQueryData(
            'getAllKeywordsByUserId',
            userId,
            (draft) => {
              const callback = (x) => x?.id === expertiseId;
              if (draft.keywords.some(callback)) {
                draft.keywords = draft.keywords.filter(({ id }) => id !== expertiseId);
              } else if (draft.hidden.some(callback)) {
                draft.hidden = draft.hidden.filter(({ id }) => id !== expertiseId);
              } else if (draft.interest.some(callback)) {
                draft.interest = draft.interest.filter(({ id }) => id !== expertiseId);
              }
            },
            true
          )
        );
        // remove expertise from recommended keywords as well
        const deleteFromRecommendedKeywords = dispatch(
          keywordsApi.util.updateQueryData('getRecommendedKeywords', userId, (draft) => draft.filter((exp) => exp.id !== expertiseId), true)
        );
        try {
          await queryFulfilled;
        } catch (error) {
          toast(error?.message || 'Failed to delete expertise !');
          deletePatchResult.undo();
          deleteFromRecommendedKeywords.undo();
        }
      }
    }),
    /** Organization Keywords */
    getAllKeywordsByOrganizationId: builder.query({
      query: (uuid) => queryEndPoints.getKeywordsByOrganizationId(uuid),
      transformResponse: (response) => response?.data?.data,
      keepUnusedDataFor: 5 * 60, // 5 minutes cache time
      providesTags: [queryTags.organizationKeywords]
    }),
    addOrganizationKeyword: builder.mutation({
      query: ({ organizationId, title }) => ({
        url: queryEndPoints.addKeywordsByOrganizationIdV1(organizationId),
        method: API_REQUEST_METHOD.POST,
        body: { title }
      }),
      transformErrorResponse: (response) => response?.data?.data?.error,
      async onQueryStarted({ organizationId }, { dispatch, queryFulfilled }) {
        try {
          const { data: createdKeyword } = await queryFulfilled;
          const newExpertise = createdKeyword?.data?.data;
          dispatch(
            keywordsApi.util.updateQueryData(
              'getAllKeywordsByOrganizationId',
              organizationId,
              (draft) => {
                draft.resultList.push(newExpertise);
              },
              true
            )
          );
        } catch (error) {
          toast.error(error?.error || 'Failed to add keyword !');
        }
      }
    }),
    deleteOrganizationKeyword: builder.mutation({
      query: ({ organizationId, keywordId }) => ({
        url: queryEndPoints.deleteKeywordByOrganizationIdAndKeywordId(organizationId, keywordId),
        method: API_REQUEST_METHOD.DELETE
      }),
      transformErrorResponse: (response) => response?.data?.data?.error,
      async onQueryStarted({ organizationId, keywordId }, { dispatch, queryFulfilled }) {
        // optimistic updates in RTK cache
        const deletePatchResult = dispatch(
          keywordsApi.util.updateQueryData(
            'getAllKeywordsByOrganizationId',
            organizationId,
            (draft) => {
              draft.resultList = draft.resultList.filter((keyword) => keyword.id !== keywordId);
            },
            true
          )
        );
        try {
          await queryFulfilled;
        } catch (error) {
          toast(error?.error || 'Failed to delete expertise !');
          deletePatchResult.undo();
        }
      }
    })
  })
});

export const {
  useGetAllKeywordsQuery,
  useLazyGetAllKeywordsQuery,
  useAddUsersKeywordMutation,
  useDeleteUsersKeywordMutation,
  useGetAllKeywordsByUserIdQuery,
  useAddOrganizationKeywordMutation,
  useGetAllKeywordsByOrganizationIdQuery,
  useDeleteOrganizationKeywordMutation,
  useGetRecommendedKeywordsQuery
} = keywordsApi;
