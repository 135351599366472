import React from 'react';
import NewsTab from './NewsTab';

{
  /* TODO : in future Update literature if there is any significant difference between contributions and literature  */
}
const LiteratureTab = () => {
  return <NewsTab showCreateButton={false} />;
};

export default LiteratureTab;
