import { Grid, Skeleton } from '@mui/material';
import React from 'react';

const ActivitiesBox = () => {
  return (
    <Grid container sx={{ mt: 2 }}>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" sx={{ fontSize: '3rem', mb: 1 }} />
        <Skeleton variant="rectangular" sx={{ fontSize: '3rem', mb: 1 }} />
        <Skeleton variant="rectangular" sx={{ fontSize: '3rem', mb: 1 }} />
        <Skeleton variant="rectangular" sx={{ fontSize: '3rem', mb: 1 }} />
      </Grid>
    </Grid>
  );
};

export default ActivitiesBox;
