// third-party
import { configureStore } from '@reduxjs/toolkit';
import { useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux';

// project import
import { menuReducer } from './reducers/menuReducer';
import { searchKeywordReducer } from './reducers/searchKeywordsReducer';
import { tourGuideReducer } from './reducers/tourGuideReducer';
import { userAPI } from './rtk-query/userApi';
import { organizationApi } from './rtk-query/organizationApi';
import { initiativesApi } from './rtk-query/initiativesApi';
import { usersListApi } from './rtk-query/usersListApi';
import { inviteeApi } from './rtk-query/inviteeApi';
import { keywordsApi } from './rtk-query/keywordsApi';
import { newsApi } from './rtk-query/newsApi';
import { networkApi } from './rtk-query/networkApi';
import { utilityApi } from './rtk-query/utilityApi';
import { chatApi } from './rtk-query/chatApi';
import { authApi } from './rtk-query/authApi';
import { licenseTransactionApi } from './rtk-query/licenseTransactionApi';
import { tourApi } from './rtk-query/tourApi';
import { notificationsApi } from './rtk-query/notificationsApi';
// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //

const store = configureStore({
  reducer: {
    [userAPI.reducerPath]: userAPI.reducer,
    [organizationApi.reducerPath]: organizationApi.reducer,
    [initiativesApi.reducerPath]: initiativesApi.reducer,
    [licenseTransactionApi.reducerPath]: licenseTransactionApi.reducer,
    [menuReducer.name]: menuReducer.reducer,
    [searchKeywordReducer.name]: searchKeywordReducer.reducer,
    [tourGuideReducer.name]: tourGuideReducer.reducer,
    [usersListApi.reducerPath]: usersListApi.reducer,
    [inviteeApi.reducerPath]: inviteeApi.reducer,
    [keywordsApi.reducerPath]: keywordsApi.reducer,
    [newsApi.reducerPath]: newsApi.reducer,
    [networkApi.reducerPath]: networkApi.reducer,
    [utilityApi.reducerPath]: utilityApi.reducer,
    [chatApi.reducerPath]: chatApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [tourApi.reducerPath]: tourApi.reducer,
    [notificationsApi.reducerPath]: notificationsApi.reducer
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({ serializableCheck: false }),
    userAPI.middleware,
    organizationApi.middleware,
    initiativesApi.middleware,
    licenseTransactionApi.middleware,
    usersListApi.middleware,
    inviteeApi.middleware,
    keywordsApi.middleware,
    newsApi.middleware,
    networkApi.middleware,
    utilityApi.middleware,
    chatApi.middleware,
    authApi.middleware,
    tourApi.middleware,
    notificationsApi.middleware
  ]
});

const { dispatch } = store;

const useDispatch = () => useAppDispatch();
const useSelector = useAppSelector;

export { store, dispatch, useSelector, useDispatch };
