import { Grid, Skeleton, Stack } from '@mui/material';
import React from 'react';

const InviteesLoading = () => {
  return (
    <Stack gap={1}>
      <Grid sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Skeleton variant="circular" width={30} height={30} />
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
      </Grid>
      <Grid sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Skeleton variant="circular" width={30} height={30} />
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
      </Grid>
      <Grid sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Skeleton variant="circular" width={30} height={30} />
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
      </Grid>
    </Stack>
  );
};

export default InviteesLoading;
