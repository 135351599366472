import PropTypes from 'prop-types';
import { forwardRef } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Card, CardContent, CardHeader, Divider } from '@mui/material';

// header style
const headersx = {
  p: 2.5,
  cursor: 'pointer',
  '& .MuiCardHeader-action': { m: '0px auto', alignSelf: 'center' },
  '&.MuiCardHeader-root': {
    padding: '20px 20px 0px 20px'
  },
  '&.MuiCardContent-root': {
    padding: '8px 20px'
  },
  '& .MuiCardHeader-content': {
    display: 'flex',
    gap: '10px',
    alignItems: 'center'
  },
  '& .MuiCardHeader-title': (theme) => ({
    fontSize: '24px',
    color: theme.palette.primary.main
  }),
  '& .MuiTypography-root': {
    width: '90%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1
  },
  '& .MuiCardHeader-subheader': {
    display: 'flex',
    alignItems: 'center'
  }
};

// ==============================|| CUSTOM - MAIN CARD ||============================== //

const MainCard = forwardRef(
  (
    {
      border = true,
      boxShadow,
      children,
      subheader,
      content = true,
      contentSX = {},
      darkTitle,
      divider = false,
      elevation,
      secondary,
      shadow,
      sx = {},
      title,
      modal = false,
      className = '',
      onClickCardHeader,
      ...others
    },
    ref
  ) => {
    const theme = useTheme();
    boxShadow = theme.palette.mode === 'dark' ? boxShadow || true : boxShadow;

    return (
      <Card
        elevation={elevation || 0}
        ref={ref}
        className={className}
        {...others}
        sx={{
          position: 'relative',
          border: border ? '1px solid' : 'none',
          borderRadius: 1,
          borderColor: theme.palette.mode === 'dark' ? theme.palette.divider : theme.palette.primary.lighter,
          boxShadow: boxShadow && (!border || theme.palette.mode === 'dark') ? shadow || theme.customShadows.z1 : 'inherit',
          ':hover': {
            boxShadow: boxShadow ? shadow || theme.customShadows.z1 : 'inherit'
          },
          ...(modal && {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: `calc( 100% - 50px)`, sm: 'auto' },
            '& .MuiCardContent-root': {
              overflowY: 'auto',
              minHeight: 'auto',
              maxHeight: `calc(100vh - 200px)`
            }
          }),
          ...sx
        }}
      >
        {/* card header and action */}
        {!darkTitle && title && (
          <CardHeader
            className="CardHeaderCardHeaderCardHeader"
            sx={{
              ...headersx,
              width: '95%',
              '& .MuiTypography-root': {
                fontSize: {
                  xs: '14px',
                  msm: '24px'
                }
              }
            }}
            titleTypographyProps={{ variant: 'subtitle1' }}
            title={title}
            action={secondary}
            subheader={subheader}
            onClick={onClickCardHeader}
          />
        )}

        {/* content & header divider */}
        {title && divider && <Divider />}

        {/* card content */}
        {content && <CardContent sx={contentSX}>{children}</CardContent>}
        {!content && children}
      </Card>
    );
  }
);

MainCard.propTypes = {
  border: PropTypes.bool,
  boxShadow: PropTypes.bool,
  children: PropTypes.node,
  subheader: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  content: PropTypes.bool,
  contentClass: PropTypes.string,
  contentSX: PropTypes.object,
  darkTitle: PropTypes.bool,
  divider: PropTypes.bool,
  elevation: PropTypes.number,
  secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
  shadow: PropTypes.string,
  sx: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
  modal: PropTypes.bool,
  onClickCardHeader: PropTypes.func,
  className: PropTypes.string
};

export default MainCard;
