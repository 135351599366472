import React from 'react';
import ReactHotToaster from 'components/Toast/ReactHotToaster';
import Locales from 'utils/Locales';
import ErrorBoundary from './components/ErrorBoundary';
import Routes from 'routes';
import ThemeCustomization from 'themes';

// ===============|| APP - THEME, ROUTER, LOCAL  ||================ //

const App = () => {
  return (
    <ErrorBoundary>
      <ThemeCustomization>
        <Locales>
          <ReactHotToaster />
          <Routes />
        </Locales>
      </ThemeCustomization>
    </ErrorBoundary>
  );
};

export default App;
