import { useMemo } from 'react';
import newMessage from './../assets/sounds/newMessage.mp3';

const usePlayAudio = () => {
  const audio = useMemo(() => new Audio(newMessage), []);
  return {
    audio
  };
};

export default usePlayAudio;
