import { useMemo } from 'react';

const useTabsData = (fieldConfig, data = {}, dataTabsLoading, type = false) => {
  const cloneFieldConfig = { ...fieldConfig };
  delete cloneFieldConfig['personDetails'];
  const updateFieldConfig = type ? cloneFieldConfig : fieldConfig;
  const tabsData = useMemo(() => {
    return Object.entries(updateFieldConfig).map(([field, value]) => {
      if (!dataTabsLoading && Object.hasOwn(data, field)) {
        return {
          ...value,
          count: data[field]
        };
      } else {
        return {
          ...value
        };
      }
    });
  }, [data, dataTabsLoading, updateFieldConfig]);
  return tabsData;
};

export default useTabsData;
