import { createApi } from '@reduxjs/toolkit/query/react';
import toast from 'react-hot-toast';
import { initiativesApi } from './initiativesApi';
import { RtkBaseQuery } from 'utils/request';
import queryTags from 'utils/constants/queryTags';
import queryEndPoints from 'utils/queryEndPoints';
import { API_REQUEST_METHOD } from 'utils/constants/globalConstant';

export const inviteeApi = createApi({
  reducerPath: 'inviteeApi',
  baseQuery: RtkBaseQuery,
  tagTypes: [queryTags.addInvitee, queryTags.getInitiativeInvitee],
  endpoints: (builder) => ({
    getInitiativeInvitee: builder.query({
      query: (params) => {
        return queryEndPoints.getInitiativeInvitee(params);
      },
      transformResponse: (response) => response?.data?.data,
      providesTags: [queryTags.getInitiativeInvitee]
    }),
    getOrganizationAuthority: builder.query({
      query: (params) => {
        return queryEndPoints.getOrganizationAuthority(params);
      },
      transformResponse: (response) => response?.data?.data,
      providesTags: [queryTags.getInitiativeInvitee]
    }),
    addInvitee: builder.mutation({
      query: ({ initiativeId, ...body }) => {
        return {
          url: queryEndPoints.addInvitee({ initiativeId }),
          method: API_REQUEST_METHOD.POST,
          body
        };
      },
      transformResponse: (response) => response?.data?.data,
      transformErrorResponse: (response) => response.data.data.error,
      async onQueryStarted({ initiativeId }, { dispatch, queryFulfilled }) {
        try {
          const { data: addInviteeData } = await queryFulfilled;
          dispatch(
            inviteeApi.util.updateQueryData('getInitiativeInvitee', { initiativeId }, (prev) => {
              prev?.unshift(addInviteeData);
            })
          );
          dispatch(initiativesApi.util.invalidateTags([queryTags.getRecommendedNetwork]));
        } catch (error) {
          toast.error(error?.error || 'Failed to add invitee!');
        }
      }
    }),
    addOrganizationAuthority: builder.mutation({
      query: ({ initiativeId, ...body }) => {
        return {
          url: queryEndPoints.addOrganizationAuthority({ initiativeId }),
          method: API_REQUEST_METHOD.POST,
          body
        };
      },
      transformResponse: (response) => response?.data?.data,
      transformErrorResponse: (response) => response.data.data.error,
      async onQueryStarted({ initiativeId }, { dispatch, queryFulfilled }) {
        try {
          const { data: addInviteeData } = await queryFulfilled;
          dispatch(
            inviteeApi.util.updateQueryData('getOrganizationAuthority', { initiativeId }, (prev) => {
              prev?.unshift(addInviteeData);
            })
          );
          dispatch(initiativesApi.util.invalidateTags([queryTags.getRecommendedNetwork]));
          dispatch(
            initiativesApi.util.updateQueryData('initiativesTabsCount', initiativeId, (prev) => {
              prev.activities += 1;
            })
          );
        } catch (error) {
          toast.error(error?.error || 'Failed to add invitee!');
        }
      }
    }),
    deleteInitiativeInvitee: builder.mutation({
      query: (params) => {
        return {
          url: queryEndPoints.deleteInitiativeInvitee(params),
          method: API_REQUEST_METHOD.DELETE
        };
      },
      async onQueryStarted({ initiativeId, inviteeUserId }, { dispatch, queryFulfilled }) {
        const deleteInitiativeInvitee = dispatch(
          inviteeApi.util.updateQueryData('getInitiativeInvitee', { initiativeId }, (prev) => {
            return prev?.filter(({ uuid }) => uuid !== inviteeUserId);
          })
        );
        try {
          await queryFulfilled;
          dispatch(initiativesApi.util.invalidateTags([queryTags.getRecommendedNetwork]));
        } catch (error) {
          toast(error?.message || 'Failed to delete invitee!');
          deleteInitiativeInvitee.undo();
        }
      },
      transformResponse: (response) => response?.data?.data,
      transformErrorResponse: (response) => response?.data
    }),
    deleteOrganizationAuthority: builder.mutation({
      query: (params) => {
        return {
          url: queryEndPoints.deleteOrganizationAuthority(params),
          method: API_REQUEST_METHOD.DELETE
        };
      },
      async onQueryStarted({ initiativeId, inviteeUserId }, { dispatch, queryFulfilled }) {
        const deleteOrganizationAuthority = dispatch(
          inviteeApi.util.updateQueryData('getOrganizationAuthority', { initiativeId }, (prev) => {
            return prev?.filter(({ uuid }) => uuid !== inviteeUserId);
          })
        );
        try {
          await queryFulfilled;
          dispatch(initiativesApi.util.invalidateTags([queryTags.getRecommendedNetwork]));
          dispatch(
            initiativesApi.util.updateQueryData('initiativesTabsCount', initiativeId, (prev) => {
              prev.activities += 1;
            })
          );
        } catch (error) {
          toast(error?.message || 'Failed to delete invitee!');
          deleteOrganizationAuthority.undo();
        }
      },
      transformResponse: (response) => response?.data?.data,
      transformErrorResponse: (response) => response?.data
    }),
    setInviteeStatus: builder.query({
      query: ({ initiativeId, inviteeId, status }) => queryEndPoints.setInitiativeInviteeStatus(initiativeId, inviteeId, status)
    }),
    setAdminInviteeStatus: builder.query({
      query: ({ initiativeId, administratorId, token, status }) =>
        queryEndPoints.setAdminInviteeStatus(initiativeId, administratorId, token, status)
    })
  })
});

export const {
  useSetInviteeStatusQuery,
  useSetAdminInviteeStatusQuery,
  useGetInitiativeInviteeQuery,
  useGetOrganizationAuthorityQuery,
  useDeleteInitiativeInviteeMutation,
  useAddInviteeMutation,
  useAddOrganizationAuthorityMutation,
  useDeleteOrganizationAuthorityMutation
} = inviteeApi;
